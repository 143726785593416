import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { SlArrowRight } from "react-icons/sl";
import { useRef } from "react";
// import HorizontalTimeline from 'react-horizontal-timeline';
import '../ipotimeline.css';
import '../companyinfo.css';
// import '../ipodetails.css'
// import { TbArrowBadgeRightFilled } from "react-icons/tb";
// import { BiMessageAlt } from 'react-icons/bi';
// import { PiArrowFatRightFill } from "react-icons/pi";

// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import TimelineOppositeContent, {  timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import ConfirmDialog from './ConfirmDialog';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
// import { makeStyles } from '@material-ui/core/styles';
// import { HiBars4, HiCog6Tooth } from "react-icons/hi2";
// import { Diversity1Sharp, Diversity2Outlined } from "@mui/icons-material";
// import ConfirmDialog from './ConfirmDialog';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Helmet } from 'react-helmet';
import { hostInfo, token  } from '../server';
import { MdSubscriptions } from "react-icons/md";
// import { parse, isValid } from 'date-fns';
import IPOStatusComponent from "./IPOStatusComponent";
// import ShareButton from './ShareButton';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../FaqComponent.css';
import DOMPurify from 'dompurify';
// import zIndex from "@mui/material/styles/zIndex";

const HtmlContent = ({ htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <div style={{fontSize:"14px",borderTop:"1px solid #ccc",padding:"0px 10px"}}>
    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} /></div>
  );
};

const IPOTimeline = ({ data , currentDate }) => {

  const containerRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);



  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX); // Only move horizontally
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  if (!data || !data.columns || !data.data) {
    return null; // Return null or handle the case where data is not available
  }
const column = data.columns && data.columns?.slice(2,-1).map(item => item.replace(/_/g, ' '));

const dates = data.data && data.data.slice(2,-1)

const lastcolumn = data.columns && data.columns?.slice(-1).map(item => item.replace(/_/g, ' '));
// console.log(lastcolumn)

const lastdates = data.data && data.data.slice(-1)
// console.log(lastdates)

  const isBeforeCurrentDate = (dateString) => {
    const date = new Date(dateString);
    return date <= currentDate;
  };

  // console.log(data)

  // Prepare data for HorizontalTimeline
  const items = column.map((columnName, index) => ({
    date: new Date(dates[index]),
    label: columnName,
  }));

  return (
  // <div>
 

      <div className="timeline">
      <label style={{ textAlign: 'left' ,marginBottom:"5px"}}>
            <label className="column" style={{ fontWeight: 'medium' }}>
              {lastcolumn}
            </label>{' '}
            <label className="date1">({lastdates})</label>
      </label>
      <div ref={containerRef}
      style={{
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        "-ms-overflow-style": "none",
         "scrollbar-width": "none" ,
        paddingTop:"5px"
      }}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove} >
        <table>
          <tbody>
            <tr style={{padding:"20px 20px"}}>
              {items.map((item, index) => (<>
                <div  style={{display:"flex",flexWrap:"nowrap",flexDirection:"row",alignContent:"center"}}>
                {/* <TbArrowBadgeRightFilled /> */}
                {/* <PiArrowFatRightFill  size={"40px"}  /> */}
                {/* <hr/> */}
                {index !== 0 && (
  <p style={{ padding: "10px 0px" }}>
    <MdOutlineKeyboardDoubleArrowRight
      key={index}
      style={{
        color: isBeforeCurrentDate(dates[index]) ? "rgb(103, 103, 148)" : "#888"
      }}
      size={isBeforeCurrentDate(dates[index]) ? "30" : "25"}
    />
  </p>
)}

                <td key={index} >
                  <div  className={isBeforeCurrentDate(dates[index]) ? 'highlight' : 'cell-content'}>
                    <span className="column">{item.label}</span>
                    {/* <span className="date">{item.date.toLocaleDateString()}</span> */}
                    <span  className={isBeforeCurrentDate(dates[index]) ? 'date' : 'date1'}>{dates[index]}</span>
                  </div>
                </td>
                </div>
                {/* <MdOutlineKeyboardDoubleArrowRight /> */}
                </>
              ))}
            </tr>
          </tbody>
        </table>
        </div>
      {/* </div> */}

    {/* <div className="timeline">
      <table>
        <caption style={{textAlign:"left",marginBottom:"10px"}}><label className="column" style={{fontWeight:"medium"}}>{lastcolumn}</label> <label className="date">({lastdates})</label></caption>
        <tbody>
          <tr>
            {column.map((columnName, index) => (
              <td key={index} >
                <div className={isBeforeCurrentDate(dates[index]) ? 'highlight' : 'cell-content'}>       
                  <span className="column">{columnName}</span>
                  <span className="date">{dates[index]}</span>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>  */}
     </div>
  );
};




// const IPOComponent = ({ targetString, ipoPerformanceData }) => {
//   console.log("ipoPerformanceData",targetString);
  
//   const targetWords = new Set((targetString || '')?.toLowerCase().split(' '));

//   const countMatchingWords = (companyName) => {
//     const companyWords = new Set(companyName?.toLowerCase().split(' '));
//     const intersection = [...targetWords].filter(word => companyWords.has(word));
//     return intersection.length;
//   };

//   const matchingRows = ipoPerformanceData?.map(row => ({
//     ...row,
//     Matching_Words: countMatchingWords(row.Company_Name)
//   }));

//   const maxMatchingWords = matchingRows.length > 0 ? Math.max(...matchingRows?.map(row => row.Matching_Words)) : 0;
  
//   const bestMatchRow = matchingRows.find(row => row.Matching_Words === maxMatchingWords) || {};

//   const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow;
//   console.log("ipoPerformanceData",Company_Name, Current_Price, Profit_Loss);

//   return (
//     <>
//       {Profit_Loss && (Profit_Loss !== '' || Profit_Loss !== null) && (
//         <Link to={`/ipo/${targetString?.toLowerCase().replace(/ /g, '-')}`} style={{ textDecoration: "none" }}>
//           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "14px", color: "#888", textAlign: "start" }}>
//             <div style={{ marginRight: "5px",color: "#888" }}>Closing Price:</div>
//             <div style={{ color: Profit_Loss.replace("%",'') < 0 ? 'red' : 'green' }}>
//               {Current_Price.replace("â¹","₹")} (<span>{Profit_Loss}</span>)
//             </div>
//           </div>
//         </Link>
//       )}
//     </>
//   );
// };



const IPOComponent = ({ targetString, ipoPerformanceData ,row11, row12}) => {
  // console.log("ipoPerformanceData", targetString, row11, row12);
  
  const targetWords = new Set((targetString || '').toLowerCase().split(' '));

  const countMissingWords = (companyName) => {
    const companyWords = new Set(companyName?.toLowerCase().split(' '));
    const missingWords = [...targetWords].filter(word => !companyWords.has(word));
    return missingWords.length;
  };

  const matchingRows = ipoPerformanceData?.map(row => {
    const missingWordsCount = countMissingWords(row.Company_Name);
    return {
      ...row,
      Missing_Words: missingWordsCount
    };
  });

  // Find the row with the least missing words, but allow at most one missing word
  const bestMatchRow = matchingRows?.find(row => row.Missing_Words <= 1) || {};

  const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow.Missing_Words > 1 ? {} : bestMatchRow;
  // console.log("ipoPerformanceData", Company_Name, Current_Price, Profit_Loss);

  return (
    <>
      {Profit_Loss && (Profit_Loss !== '' || Profit_Loss !== null) ? (
        <Link to={`/ipo/${targetString?.toLowerCase().replace(/ /g, '-')}`} style={{ textDecoration: "none" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "14px", color: "#888", textAlign: "start" }}>
            <div style={{ marginRight: "5px", color: "#888" }}>Closing Price:</div>
            <div style={{ color: Profit_Loss.replace("%", '') < 0 ? 'red' : 'green' }}>
              {Current_Price.replace("â¹", "₹")} (<span>{Profit_Loss}</span>)
            </div>
          </div>
        </Link>
      ):((row11 !== '' && row11 !== null && row11 !== undefined && row12 !== '' && row12 !== null && row12 !== undefined) ? (<><Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "14px", color: "#888", textAlign: "start"}} 
      // style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
      >
      <div style={{marginRight:"5px",color: "#888"}}>Exp. Premium:</div>{row11 < 0 ? (<div style={{color:'red'}}> {row11} {row12 && row12?.split(' ')[1]}</div>):(<div style={{color:'green'}}> {row11} {row12 && row12?.split(' ')[1]}</div>)}
      </div></Link></>):(<><Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "14px", color: "#888", textAlign: "start"}} 
      // style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
      >
      <div style={{marginRight:"5px",color: "#888"}}>Exp. Premium:</div><div style={{color:'black'}}>Not Available</div>
      </div></Link></>))}
    </>
  );
};





function IPODetails({deviceInfo,navigation}) {
 
  const [allIPOsPerformance, setAllIPOsPerformance] = useState([]);
 

  useEffect(() => {
    fetch(`${hostInfo}/performance`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setAllIPOsPerformance(data);})
  }, []);

  const containerRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const currentDate = new Date();

//   const [active,setactive] = useState("details");
// console.log(active);

  const [expanded, setExpanded] = useState(null);
 

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  // const stripTime = (date) => {
  //   const newDate = new Date(date);
  //   newDate.setHours(0, 0, 0, 0);
  //   return newDate;
  // };


  // const [isHovered, setIsHovered] = useState(false);

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   const day = date.getDate();
  //   const month = date.toLocaleString('en-us', { month: 'short' });
  //   const year = date.getFullYear();
    
  //   return `${date.toLocaleString('en-us', { weekday: 'short' })}, ${day} ${month} ${year}`;
  // }
  // const tooltipText = data && data[2];
 
  // Function to truncate text to 3-4 words
  // const truncateText = (text, wordLimit) => {
  //   const words = text?.split(' ');
  //   if (words?.length > wordLimit) {
  //     return words?.slice(0, wordLimit).join(' ') + '...';
  //   }
  //   return text;
  // };

  // const truncatedText = truncateText(tooltipText, 4); // Truncate to 4 words

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX); // Only move horizontally
    containerRef.current.scrollLeft = scrollLeft - walk;
  };


  const tab1Ref = useRef(null);
  const tab2Ref = useRef(null);
  const tab3Ref = useRef(null);
  const tab4Ref = useRef(null);
  const tab5Ref = useRef(null);
  const placeholderRef = useRef(null);
  
  const [isButtonClicked, setIsButtonClicked] = useState(false);


  const [active, setactive] = useState("details");

  


  // useEffect(() => {
  //   // if (!isButtonClicked) {
  //     let timeoutId;

  //     const handleScroll = () => {
  //       clearTimeout(timeoutId); // Clear previous timeout
  //       timeoutId = setTimeout(() => {
  //         const viewportHeight = window.innerHeight;

  //         const tab1Top = tab1Ref.current ? tab1Ref.current.getBoundingClientRect().top : Infinity;
  //         const tab2Top = tab2Ref.current ? tab2Ref.current.getBoundingClientRect().top : Infinity;
  //         const tab3Top = tab3Ref.current ? tab3Ref.current.getBoundingClientRect().top : Infinity;
  //         const tab4Top = tab4Ref.current ? tab4Ref.current.getBoundingClientRect().top : Infinity;
  //         const tab5Top = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().top : Infinity;

  //         const tab1bottom= tab1Ref.current ? tab1Ref.current.getBoundingClientRect().bottom : Infinity;
  //         const tab2bottom = tab2Ref.current ? tab2Ref.current.getBoundingClientRect().bottom : Infinity;
  //         const tab3bottom = tab3Ref.current ? tab3Ref.current.getBoundingClientRect().bottom : Infinity;
  //         const tab4bottom = tab4Ref.current ? tab4Ref.current.getBoundingClientRect().bottom : Infinity;
  //         const tab5bottom = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().bottom : Infinity;

  //         // const centerOfScreen = viewportHeight / 2; // Center of the viewport
  //         // (tab1bottom >0 && tab1bottom < viewportHeight )
  //         if ((tab1Top >= 0 && tab1Top < viewportHeight)) {
  //           // if (Math.abs(tab1bottom - centerOfScreen) < 10) { // Adjust the threshold as needed
  //           //   setactive("details");
  //           // }
  //          setactive("details");

  //         } else if (tab2Top >= 0 && tab2Top < viewportHeight) {
  //           setactive("news");
  //         } else if (tab3Top >= 0 && tab3Top < viewportHeight) {
  //           setactive("subscription");
  //         } else if (tab4Top >= 0 && tab4Top < viewportHeight) {
  //           setactive("allotment");
  //         } else if (tab5Top >= 0 && tab5Top < viewportHeight) {
  //           setactive("calculator");
  //         }
  //       }, 100); // Adjust the timeout as needed
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //       clearTimeout(timeoutId); // Clean up timeout on unmount
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   // }
  // }, [isButtonClicked, tab1Ref, tab2Ref, tab3Ref, tab4Ref, tab5Ref]);
  
  
  useEffect(() => {
    let timeoutId;
  
    const handleScroll = () => {
      clearTimeout(timeoutId); // Clear previous timeout
      timeoutId = setTimeout(() => {
        const viewportHeight = window.innerHeight;
  
        // Get the heights and positions of each tab
        const tab1Top = tab1Ref.current ? tab1Ref.current.getBoundingClientRect().top : Infinity;
        const tab2Top = tab2Ref.current ? tab2Ref.current.getBoundingClientRect().top : Infinity;
        const tab3Top = tab3Ref.current ? tab3Ref.current.getBoundingClientRect().top : Infinity;
        const tab4Top = tab4Ref.current ? tab4Ref.current.getBoundingClientRect().top : Infinity;
        const tab5Top = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().top : Infinity;
  
        const tab1Bottom = tab1Ref.current ? tab1Ref.current.getBoundingClientRect().bottom : Infinity;
        const tab2Bottom = tab2Ref.current ? tab2Ref.current.getBoundingClientRect().bottom : Infinity;
        const tab3Bottom = tab3Ref.current ? tab3Ref.current.getBoundingClientRect().bottom : Infinity;
        const tab4Bottom = tab4Ref.current ? tab4Ref.current.getBoundingClientRect().bottom : Infinity;
        const tab5Bottom = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().bottom : Infinity;
  
        const fortyPercentViewportHeight = viewportHeight * 0.4; // 40% of viewport height

      // Check if the tab is visible in the viewport (either from the top or bottom 40%)
      const isTabVisible = (tabTop, tabBottom) => {
        return (
          (tabTop >= 0 && tabTop < viewportHeight) || // Top is visible
          (tabBottom >= fortyPercentViewportHeight && tabBottom < viewportHeight) // Bottom is 40% visible
        );
      };
  
        if (isTabVisible(tab1Top, tab1Bottom)) {
          setactive("details");
        } else if (isTabVisible(tab2Top, tab2Bottom)) {
          setactive("news");
        } else if (isTabVisible(tab3Top, tab3Bottom)) {
          setactive("subscription");
        } else if (isTabVisible(tab4Top, tab4Bottom)) {
          setactive("allotment");
        } else if (isTabVisible(tab5Top, tab5Bottom)) {
          setactive("calculator");
        }
      }, 100); // Adjust the timeout as needed
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      clearTimeout(timeoutId); // Clean up timeout on unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isButtonClicked, tab1Ref, tab2Ref, tab3Ref, tab4Ref, tab5Ref]);
  
  

  // const scrollToRef = (ref) => {
  //   window.scrollTo({
  //     top: ref.current.offsetTop-165,
  //     behavior: 'smooth'
  //   });
  // };

  const scrollToRef = (ref, className) => {
    
    let element = null;

    if (ref && ref.current) {
      element = ref.current;
    } else if (className) {
      element = document.querySelector(`.${className}`);
    }

    if (element) {
      let offset = 0; // Default offset
      if (className === 'laptop-view') {
        offset = 165; // Offset for laptop-view
      } else if (className === 'phone-view') {
        offset = 80; // Offset for phone-view
      }

      window.scrollTo({
        top: element.offsetTop - offset,
        behavior: 'smooth'
      });
    }
  };
  
  


  // const [ipoType, setIpoType] = useState("All IPO");

  // const handleIpoTypeChange = (type) => {
  //   setIpoType(type);
  //   // setActiveTab(type);
  //   // setShowAllRows(false);
  // };

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#e3914e",
      // backgroundColor: "rgba(161,123,173,1)",
      // background: "linear-gradient(90deg, rgba(177,93,25,1) 0%, rgba(222,164,88,1) 100%, rgba(247,197,58,1) 100%)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: "#fd9e7b",
      backgroundColor: "rgba(161,123,173,1)",
      // background: "linear-gradient(90deg, rgba(177,93,25,1) 0%, rgba(222,164,88,1) 100%, rgba(247,197,58,1) 100%)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


 
  const Params = useParams();
  // const  encodedParam  = useParams();

  // Decode the parameter
  // const decodedParam = decodeURIComponent(encodedParam).replace(/-/g, ' ');

  // Find the original data entry
  // const originalData = data.find(item => item.toLowerCase() === decodedParam);
  // console.log(Params["ipo-details"]);
  const nevigate = useNavigate();

  const [ipoDetails, setIpoDetails] = useState([]);
  console.log(ipoDetails,"..................")






  const transformData = (input) => {
    const result = {
      columns: [],
      data: []
    };
  
    input && input[0]?.data?.forEach((item, index) => {
      try {
        const parsedItem = JSON.parse(item);
        if (parsedItem.hasOwnProperty("Subscription (times)")) {
          result.columns.push(input[0].columns[index]);
          result.data.push(parsedItem["Subscription (times)"]);
        }
      } catch (error) {
        // Skip if JSON parsing fails
      }
    });
  
    return result;
  };
  
  const transformedObject = transformData(ipoDetails && ipoDetails?.ipo_subscription_status);
  
  // console.log(transformedObject);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${hostInfo}/get_ipo_details`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
            'Content-Type': 'application/json', // Set the Content-Type if necessary
          },
          body: JSON.stringify({ company_name: Params["ipo-details"] })
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        // console.log(data)
        setIpoDetails(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        nevigate('/');
        // Handle the error as needed
      }
    };
  
    fetchData();

  }, [Params["ipo-details"]]);

  const [blogs, setBlogs] = useState([]);
  console.log(blogs,"..................")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${hostInfo}/get_blogs`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
            'Content-Type': 'application/json', // Set the Content-Type if necessary
          },
          body: JSON.stringify({ company_name: ipoDetails?.IssuerCompany })
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        // console.log(data)
        setBlogs(data.blog_info);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error as needed
      }
    };
  
    fetchData();

  }, [ipoDetails?.IssuerCompany]);
  
  
// --------------------------------------------------------------------



// const column = ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0].columns.slice(2).map(item => item.replace(/_/g, ' '));

// const dates = ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0].data.slice(2,-1)
// console.log(column)
// const classes = useStyles();


// --------------------------------------------------


// // const HorizontalTimeline = () => {
//   const [today, setToday] = useState(new Date());
//   const [highlightedDateIndex, setHighlightedDateIndex] = useState(-1);

//   useEffect(() => {
//     highlightMatchingDate();
//   }, []);

//   const highlightMatchingDate = () => {
//     ipoDetails.ipo_timeline[0].forEach((timelineItem, index) => {
//       const ipoDate = new Date(timelineItem.data[2]); // Convert IPO date string to Date object
//       if (isSameDate(today, ipoDate)) {
//         setHighlightedDateIndex(index);
//       }
//     });
//   };

//   const isSameDate = (date1, date2) => {
//     return date1.getFullYear() === date2.getFullYear() &&
//            date1.getMonth() === date2.getMonth() &&
//            date1.getDate() === date2.getDate();
//   };

// --------------------------------------------------------------------
  // const data1 = [
  //   ["03 Apr 24- 5 Apr 24", "₹ 14820", "542 - 570", "26"],
  // ];
  

  let ipo_financial_information_columns, ipo_financial_information_rowData, ipo_financial_information_amountInCaption; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails?.ipo_financial_information) {
      const columnsWithData = ipoDetails?.ipo_financial_information[0]?.columns;
      const data = ipoDetails?.ipo_financial_information[0]?.data;
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // Check if length is sufficient for slicing
          if (length >= 10) {
                const columnsData = columnsWithData.filter((column, index) => {
    // Exclude the last item which is a label
    if (index === columnsWithData.length - 1) return false;
    const dataValue = data[index];
    return dataValue && dataValue !== "{}";
  });
              const col = columnsData.slice(2, 10);
              // console.log(col);
  
              ipo_financial_information_columns = ["Period Ended", ...col]; // Assign value to columns variable
              // console.log(ipo_financial_information_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length - 1 && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
  
              ipo_financial_information_rowData = [];
              Object.keys(JSON.parse(data[2])).forEach((date) => {
                  const row = [date];
                  for (let i = 2; i < 10; i++) {
                      if (filteredDataIndexes.includes(i)) {
                          row.push(JSON.parse(data[i])[date] || "-");
                      }
                  }
                  ipo_financial_information_rowData.push(row);
              });
              // console.log(ipo_financial_information_rowData);
  
              // Assign value to amountInCaption variable
              ipo_financial_information_amountInCaption = data[10];
          } else {
              console.error("Not enough elements to slice.");
          }
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or ipo_financial_information is undefined or null.");
  }
  

  
  let ipo_reservation_columns, ipo_reservation_rowData; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails?.ipo_reservation) {
      const columnsWithData = ipoDetails?.ipo_reservation[0]?.columns;
      // console.log(columnsWithData)
      const data = ipoDetails?.ipo_reservation[0]?.data;
      // console.log(data)
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          // const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // // Check if length is sufficient for slicing
          // if (length >= 10) {
              const columnsData = columnsWithData?.filter((column, index) => {
                // Exclude the last item which is a label
                if (index === columnsWithData.length) return false;
                const dataValue = data[index];
                return dataValue && dataValue !== "{}";
              });
              const col = columnsData.slice(2);
              // console.log(col);
  
              ipo_reservation_columns = ["Investor Category", ...col]; // Assign value to columns variable
              // console.log(ipo_reservation_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
              // console.log(filteredDataIndexes)
  
              ipo_reservation_rowData = [];
              // Object.keys(JSON.parse(data[2])).forEach((date) => {
              //     const row = [date];
               
              //     for (let i = 2; i <= data.length; i++) {
              //         if (filteredDataIndexes.includes(i)) {
              //             row.push(JSON.parse(data[i])[date] || "-");
              //         }
              //     }
              //     ipo_reservation_rowData.push(row);
              // });
              // console.log(ipo_reservation_rowData);

              filteredDataIndexes.forEach((index) => {
                Object.keys(JSON.parse(data[index])).forEach((date) => {
                    const rowData = ipo_reservation_rowData.find(row => row[0] === date);
                    if (rowData) {
                        rowData.push(JSON.parse(data[index])[date] || "-");
                    } else {
                        const row = [date];
                        row.push(JSON.parse(data[index])[date] || "-");
                        ipo_reservation_rowData.push(row);
                    }
                });
                // console.log(ipo_reservation_rowData);
            
        
           });
  
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or ipo_reservation is undefined or null.");
  }
  


  let ipo_lot_size_columns, ipo_lot_size_rowData; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails.ipo_lot_size) {
      const columnsWithData = ipoDetails.ipo_lot_size && ipoDetails.ipo_lot_size[0]?.columns;
      // console.log(columnsWithData)
      const data = ipoDetails.ipo_lot_size && ipoDetails.ipo_lot_size[0]?.data;
      // console.log(data)
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          // const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // // Check if length is sufficient for slicing
          // if (length >= 10) {
              const columnsData = columnsWithData.filter((column, index) => {
                // Exclude the last item which is a label
                if (index === columnsWithData.length) return false;
                const dataValue = data[index];
                return dataValue && dataValue !== "{}";
              });
              const col = columnsData.slice(2);
              // console.log(col);
  
              ipo_lot_size_columns = ["Application", ...col]; // Assign value to columns variable
              // console.log(ipo_lot_size_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
              // console.log(filteredDataIndexes)
  
              ipo_lot_size_rowData = [];
              // Object.keys(JSON.parse(data[2])).forEach((date) => {
              //     const row = [date];
               
              //     for (let i = 2; i <= data.length; i++) {
              //         if (filteredDataIndexes.includes(i)) {
              //             row.push(JSON.parse(data[i])[date] || "-");
              //         }
              //     }
              //     ipo_reservation_rowData.push(row);
              // });
              // console.log(ipo_lot_size_rowData);

              filteredDataIndexes.forEach((index) => {
                Object.keys(JSON.parse(data[index])).forEach((date) => {
                    const rowData = ipo_lot_size_rowData.find(row => row[0] === date);
                    if (rowData) {
                        rowData.push(JSON.parse(data[index])[date] || "-");
                    } else {
                        const row = [date];
                        row.push(JSON.parse(data[index])[date] || "-");
                        ipo_lot_size_rowData.push(row);
                    }
                });
                // console.log(ipo_lot_size_rowData);
            
        
           });
  
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or ipo_lot_size is undefined or null.");
  }
  

  let pre_post_ipo_columns, pre_post_ipo_rowData; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails.pre_post_ipo) {
      const columnsWithData = ipoDetails?.pre_post_ipo[0]?.columns;
      // console.log(columnsWithData)
      const data = ipoDetails?.pre_post_ipo[0]?.data;
      // console.log(data)
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          // const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // // Check if length is sufficient for slicing
          // if (length >= 10) {
              const columnsData = columnsWithData.filter((column, index) => {
                // Exclude the last item which is a label
                if (index === columnsWithData.length) return false;
                const dataValue = data[index];
                return dataValue && dataValue !== "{}";
              });
              const col = columnsData.slice(2);
              // console.log(col);
  
              pre_post_ipo_columns = [" ", ...col]; // Assign value to columns variable
              // console.log(pre_post_ipo_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
              // console.log(filteredDataIndexes)
  
              pre_post_ipo_rowData = [];
              // Object.keys(JSON.parse(data[2])).forEach((date) => {
              //     const row = [date];
               
              //     for (let i = 2; i <= data.length; i++) {
              //         if (filteredDataIndexes.includes(i)) {
              //             row.push(JSON.parse(data[i])[date] || "-");
              //         }
              //     }
              //     ipo_reservation_rowData.push(row);
              // });
              // console.log(pre_post_ipo_rowData);

              filteredDataIndexes.forEach((index) => {
                Object.keys(JSON.parse(data[index])).forEach((date) => {
                    const rowData = pre_post_ipo_rowData.find(row => row[0] === date);
                    if (rowData) {
                        rowData.push(JSON.parse(data[index])[date] || "-");
                    } else {
                        const row = [date];
                        row.push(JSON.parse(data[index])[date] || "-");
                        pre_post_ipo_rowData.push(row);
                    }
                });
                // console.log(pre_post_ipo_rowData);
            
        
           });
  
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or pre_post_ipo is undefined or null.");
  }



  let ipo_subscription_status_columns, ipo_subscription_status_rowData; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails.ipo_subscription_status) {
      const columnsWithData =  ipoDetails.ipo_subscription_status.length > 0 && ipoDetails.ipo_subscription_status[0].columns;
      // console.log(columnsWithData)
      const data =  ipoDetails.ipo_subscription_status.length > 0 && ipoDetails.ipo_subscription_status[0].data;
      // console.log(data)
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          // const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // // Check if length is sufficient for slicing
          // if (length >= 10) {
              const columnsData = columnsWithData.filter((column, index) => {
                // Exclude the last item which is a label
                if (index === columnsWithData.length) return false;
                const dataValue = data[index];
                return dataValue && dataValue !== "{}";
              });
              const col = columnsData.slice(2);
              // console.log(col);
  
              ipo_subscription_status_columns = ["Investor Category", ...col]; // Assign value to columns variable
              // console.log(ipo_subscription_status_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
              // console.log(filteredDataIndexes)
  
              ipo_subscription_status_rowData = [];
              // Object.keys(JSON.parse(data[2])).forEach((date) => {
              //     const row = [date];
               
              //     for (let i = 2; i <= data.length; i++) {
              //         if (filteredDataIndexes.includes(i)) {
              //             row.push(JSON.parse(data[i])[date] || "-");
              //         }
              //     }
              //     ipo_reservation_rowData.push(row);
              // });
              // console.log(ipo_subscription_status_rowData);

              filteredDataIndexes.forEach((index) => {
                Object.keys(JSON.parse(data[index])).forEach((date) => {
                    const rowData = ipo_subscription_status_rowData.find(row => row[0] === date);
                    if (rowData) {
                        rowData.push(JSON.parse(data[index])[date] || "-");
                    } else {
                        const row = [date];
                        row.push(JSON.parse(data[index])[date] || "-");
                        ipo_subscription_status_rowData.push(row);
                    }
                });
                // console.log(ipo_subscription_status_rowData);
            
        
           });
  
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or ipo_subscription_status is undefined or null.");
  }


  let ipo_subscription_status_times_columns, ipo_subscription_status_times_rowData; // Declare variables outside of the if statement block

  if (ipoDetails && ipoDetails.ipo_subscription_status_times) {
      const columnsWithData =  ipoDetails.ipo_subscription_status_times.length > 0 && ipoDetails.ipo_subscription_status_times[0].columns;
      // console.log(columnsWithData)
      const data =  ipoDetails.ipo_subscription_status_times.length > 0 && ipoDetails.ipo_subscription_status_times[0].data;
      // console.log(data)
  
      // Check if columns and data are defined and not null
      if (columnsWithData && data) {
          // const length = columnsWithData.length < data.length ? columnsWithData.length : data.length;
  
          // // Check if length is sufficient for slicing
          // if (length >= 10) {
              const columnsData = columnsWithData.filter((column, index) => {
                // Exclude the last item which is a label
                if (index === columnsWithData.length) return false;
                const dataValue = data[index];
                return dataValue && dataValue !== "{}";
              });
              const col = columnsData.slice(2);
              // console.log(col);
  
              ipo_subscription_status_times_columns = ["Date", ...col]; // Assign value to columns variable
              // console.log(ipo_subscription_status_times_columns);
  
              const filteredDataIndexes = data.reduce((acc, dataValue, index) => {
                  // Exclude the last item which is a label and empty objects
                  if (index !== data.length && Object.keys(JSON.parse(dataValue)).length > 0) {
                      acc.push(index);
                  }
                  return acc;
              }, []);
              // console.log(filteredDataIndexes)
  
              ipo_subscription_status_times_rowData = [];
              // Object.keys(JSON.parse(data[2])).forEach((date) => {
              //     const row = [date];
               
              //     for (let i = 2; i <= data.length; i++) {
              //         if (filteredDataIndexes.includes(i)) {
              //             row.push(JSON.parse(data[i])[date] || "-");
              //         }
              //     }
              //     ipo_reservation_rowData.push(row);
              // });
              // console.log(ipo_subscription_status_times_rowData);

              filteredDataIndexes.forEach((index) => {
                Object.keys(JSON.parse(data[index])).forEach((date) => {
                    const rowData = ipo_subscription_status_times_rowData.find(row => row[0] === date);
                    if (rowData) {
                        rowData.push(JSON.parse(data[index])[date] || "-");
                    } else {
                        const row = [date];
                        row.push(JSON.parse(data[index])[date] || "-");
                        ipo_subscription_status_times_rowData.push(row);
                    }
                });
                // console.log(ipo_subscription_status_times_rowData);
            
        
           });
  
      } else {
          console.error("Columns or data is undefined or null.");
      }
  } else {
      console.error("ipoDetails or ipo_subscription_status_times is undefined or null.");
  }


  const pricePerShareString = ipoDetails?.ipo_details && (ipoDetails?.ipo_details[0]?.data[5] || ipoDetails?.ipo_details[0]?.data[6]);
  // console.log(pricePerShareString);
  let lastValue, pricePerShareRange1 ,pricePerShareRange2;
  if (pricePerShareString) {
    const regex = /₹(\d+) to ₹(\d+)/;
    const match = pricePerShareString.match(regex);
    const regex1 = /₹(\d+)/;
    const match1 = pricePerShareString.match(regex1);
  
    if (match) {
      const pricePerShareRange = [parseInt(match[1]), parseInt(match[2])];
      // console.log(pricePerShareRange);
      pricePerShareRange1=pricePerShareRange[pricePerShareRange.length - 1];
  
      // Accessing the last value only if pricePerShareRange is not null or undefined
      lastValue = pricePerShareRange[pricePerShareRange.length - 1] * (ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7].replace(' Shares','')); 
  
      // Safely accessing ipoDetails and its properties
      // console.log(lastValue);
    } else if(match1) {
      // const regex1 = /₹(\d+)/;
      // const match1 = pricePerShareString.match(regex1);
      const pricePerShareRange = [parseInt(match1[1])];
      pricePerShareRange2=pricePerShareRange[0];
      // Accessing the last value only if pricePerShareRange is not null or undefined
      lastValue = pricePerShareRange[0] * (ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7].replace(' Shares','')); 
  
      // Safely accessing ipoDetails and its properties
      // console.log(lastValue);
    }else{
      lastValue = '';
    }
  } else {
    lastValue = '';
    console.log("Price per share string is undefined.");
  }

  const [showAll, setShowAll] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(8);

  const handleViewMore = () => {
    setShowAll(true);
    setVisibleColumns(ipoDetails.ipo_details && ipoDetails.ipo_details[0].columns.length);
  };

  const handleShowLess = () => {
    setShowAll(false);
    setVisibleColumns(8);
  };


//   // Assuming ipoDetails is an object containing ipo_details array
// // Get the data array and columns array

let filteredData, filteredColumns;
const ipoDetailsData = ipoDetails && ipoDetails.ipo_details && ipoDetails.ipo_details[0];
if (ipoDetailsData && Array.isArray(ipoDetailsData.data) && Array.isArray(ipoDetailsData.columns)) {
    const data = ipoDetailsData.data;
    const columns = ipoDetailsData.columns;

    const nonEmptyIndices = data.reduce((acc, data, index) => {
        if (data !== '') {
            acc.push(index);
        }
        return acc;
    }, []);

    // Filter data and columns arrays based on nonEmptyIndices
    filteredData = nonEmptyIndices.map(index => data[index]);
    filteredColumns = nonEmptyIndices.map(index => columns[index]);

    // console.log(filteredData);
    // console.log(filteredColumns);
} else {
    console.log("Invalid IPO details structure or missing data.");
}

// const data = ipoDetails.ipo_details && ipoDetails.ipo_details[0].data;
// const columns = ipoDetails.ipo_details && ipoDetails.ipo_details[0].columns;

// const nonEmptyIndices = ipoDetails.ipo_details && ipoDetails.ipo_details[0].data.reduce((acc, data, index) => {
//   if (data !== '') {
//     acc.push(index);
//   }
//   return acc;
// }, []);

// // Filter data and columns arrays based on nonEmptyIndices
// const filteredData = nonEmptyIndices.map(index => data[index]);
// console.log(filteredData)
// const filteredColumns = nonEmptyIndices.map(index => columns[index]);
// // const columns = ipoDetails.ipo_details && ipoDetails.ipo_details[0].columns;
// // // Now selectedColumns contains the values from columns array corresponding to available indexes in data array
// // console.log("ipoDetails:", ipoDetails);
// // console.log("ipo_details[0]:", ipoDetails.ipo_details[0]);
// console.log("data:", data); // Check if data is defined

// // Filter the indexes where values are available
// const availableIndexes = data.slice(2).reduce((acc, val, idx) => {
//     if (val !== null && val !== undefined) {
//         acc.push(idx + 2); // Adding 2 because we're slicing from index 2
//     }
//     return acc;
// }, []);

// // Extract values from columns array using available indexes
// const selectedColumns = availableIndexes.map(index => columns[index]);

// const text="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica";

const [inputValue, setInputValue] = useState('');


// Function to handle input change
const handleInputChange = (event) => {
  const value = event.target.value;
  // Validate input: allow only numbers
  const newValue = value.replace(/\D/g, '');
  // Format the value using toLocaleString
  const formattedValue = Number(newValue).toLocaleString('en-IN');
  setInputValue(formattedValue);
};

  // Function to handle form submission
  // let lotapply, shareapply,totalamount;
  const[LotApply,setLotApply]=useState("");
  const[ShareApply,setShareApply]=useState("");
  const[TotalAmount,setTotalAmount]=useState("");
  const handleSubmit = () => {
    // Pass the inputValue to your function here
    // console.log('Input Value:', inputValue);
    // const lotApply = inputValue / lastValue;
    // const shareApply = lotApply * parseInt((ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7].replace(' Shares', '')), 10);
    // const totalAmount = lastValue * lotApply;
    const lotApply = parseInt(inputValue.toString().replace(/,/g, '') / lastValue, 10);
const shareApply = parseInt(lotApply * parseInt((ipoDetails.ipo_details && ipoDetails.ipo_details[0]?.data[7]?.replace(' Shares', '')), 10), 10);
const totalAmount = parseInt(lastValue.toString().replace(/,/g, '') * lotApply, 10);

  
    setLotApply(lotApply.toLocaleString('en-IN'));
    setShareApply(shareApply.toLocaleString('en-IN'));
    setTotalAmount(totalAmount.toLocaleString('en-IN'));
  };




  // const currentDate = new Date();
  // const openDate = new Date(ipoDetails?.OpenDate);
  // console.log(openDate);
  // console.log( "closeddate",ipoDetails?.ClosedDate);
  const closedDateString = ipoDetails?.ClosedDate; // Replace with actual closed date from ipoDetails

 
  // Parse the closedDateString using date-fns
  const closedDate = new Date(closedDateString);

  // Check if closedDate is valid
  if (isNaN(closedDate)) {
    console.error("Invalid Closed Date:", closedDateString);
  }

  const allotmentDate = ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) : null;
  const isAllotmentOut = ipoDetails?.ipo_allotment_status && ipoDetails?.ipo_allotment_status.length > 0 && ipoDetails?.ipo_allotment_status[0].data[2];

  let displayElement = null;

  // console.log("Current Date:", currentDate);
  // console.log("Closed Date:", closedDate);
  // console.log("Allotment Date:", allotmentDate);
  // console.log("Is Allotment Out:", isAllotmentOut);

  // if (currentDate <= closedDate) {
  //   displayElement = (
  //     <div style={{ fontSize: "14px", margin: "10px 0", display: "flex", alignItems: "center", gap: "10px" }}>
  //       <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
  //         <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#e13803", margin: "1px 5px 0px 0px" }}></div>
  //         Live
  //       </label>
  //     </div>
  //   );
  // } else 
  // if (closedDate < currentDate && currentDate < allotmentDate) {
  //   displayElement = (
  //     <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
  //       <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
  //         <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
  //         Allotment Awaited
  //       </label>
  //     </div>
  //   );
  // } else 
  if (currentDate >= allotmentDate && isAllotmentOut) {
    displayElement = (
      <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#dd8f00", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#fdf5e6", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#dd8f00", margin: "1px 5px 0px 0px" }}></div>
          Allotment Out
        </label>
      </div>
    );
  } else if (ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 && allotmentDate && currentDate >= allotmentDate && !isAllotmentOut) {
    displayElement = (
      <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Allotment Awaited
        </label>
      </div>
    );
  }

  // const status = getIPOCurrentStatus({
  //   openDate: ipoDetails?.OpenDate,
  //   closeDate: ipoDetails?.CloseDate,
  //   allotmentDate: ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 
  //     ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) 
  //     : null,
  //   allotmentHref: ipoDetails?.ipo_allotment_status && ipoDetails?.ipo_allotment_status.length > 0 
  //     ? ipoDetails?.ipo_allotment_status[0].data[2] 
  //     : null
  // });

  // console.log(status);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };




  const contentStyle = {
 
    maxHeight: isExpanded ? 'none' : '8em',
    overflow: isExpanded ? 'visible' : 'hidden',
    position: 'relative',
    maskImage: isExpanded ? 'none' : 'linear-gradient(to bottom, #fff 10%, transparent)', // Set to 'none' when expanded
    WebkitMaskImage: isExpanded ? 'none' : 'linear-gradient(to bottom, #fff 10%, transparent)', // Set to 'none' when expanded
    zIndex: -1, 
    
};


const faqData = [
  {
    question: `What is ${ipoDetails?.IssuerCompany}?`,
    answer: `<div>
    <p>${ipoDetails?.IssuerCompany} is a <strong>${ipoDetails?.ipo_details?.length > 0 && ((ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() === "SME" && "SME") || (ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() !== "SME" && "main-board")) ? ((ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() === "SME" && "SME") || (ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() !== "SME" && "main-board")) :"[.]"} IPO of ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[8].split(" ")[0] ? ipoDetails?.ipo_details?.[0]?.data?.[8].split(" ")[0] :"[.]"} equity shares</strong> of the face value of ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[4].replace(" per share","")? ipoDetails?.ipo_details?.[0]?.data?.[4].replace(" per share","") : "[.]"} ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[8].split("(")[1].replace(" Cr)","") ? ipoDetails?.ipo_details?.[0]?.data?.[8].split("(")[1].replace(" Cr)","") : "[.]"} Crores. The issue is priced at ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[6] ? ipoDetails?.ipo_details?.[0]?.data?.[6] : "[.]"}. The minimum order quantity is ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[7] ? ipoDetails?.ipo_details?.[0]?.data?.[7] : "[.]"}.</p>
    <p>${ipoDetails?.OpenDate && ipoDetails?.CloseDate ? `The IPO <strong>opens on ${formatDate(ipoDetails?.OpenDate)}</strong> and <strong>closes on ${formatDate(ipoDetails?.CloseDate)}</strong>.` : `The ${ipoDetails?.IssuerCompany} open and close dates are not available as of now.`}</p>
    <p>The shares are proposed to be listed on ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[13] ? ipoDetails?.ipo_details?.[0]?.data?.[13] : "[.]"}.</p>
  </div>`
  },
  {
    question: `How to apply in ${ipoDetails?.IssuerCompany} through Zerodha?`,
    answer: `<div>
    <p>Zerodha customers can apply online in ${ipoDetails?.IssuerCompany} using UPI as a payment gateway. Zerodha customers can apply in ${ipoDetails?.IssuerCompany} by logging into Zerodha Console (back office) and submitting an IPO application form.</p>
    <p><strong>Steps to apply in ${ipoDetails?.IssuerCompany} through Zerodha:</strong></p>
    <ol>
      <li>Visit the Zerodha website and login to Console.</li>
      <li>Go to Portfolio and click the IPOs link.</li>
      <li>Go to the '${ipoDetails?.IssuerCompany}' row and click the 'Bid' button.</li>
      <li>Enter your UPI ID, Quantity, and Price.</li>
      <li>‘Submit’ IPO application form.</li>
      <li>Visit the UPI App (net banking or BHIM) to approve the mandate.</li>
    </ol>
  </div>`
  },
  {
    question: `When ${ipoDetails?.IssuerCompany} will open?`,
    answer: `<div> <p>${ipoDetails?.OpenDate && ipoDetails?.CloseDate ? `The ${ipoDetails?.IssuerCompany} <strong>opens on ${formatDate(ipoDetails?.OpenDate)}</strong> and <strong>closes on ${formatDate(ipoDetails?.CloseDate)}</strong>.` : `The ${ipoDetails?.IssuerCompany} dates are not announced. Please check back again after some time.`}</p>
    </div>`
  },
  {
    question: `What is the lot size of ${ipoDetails?.IssuerCompany}?`,
    answer: `<div>${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[7] && lastValue && lastValue !== '' ? 
    `<p>
      ${ipoDetails?.IssuerCompany} <strong>lot size is ${ipoDetails?.ipo_details?.length > 0 && ipoDetails?.ipo_details?.[0]?.data?.[7] ? ipoDetails?.ipo_details?.[0]?.data?.[7] : "[.]"}</strong>, and the <strong>minimum amount required is ₹${lastValue && lastValue !== '' ? lastValue.toLocaleString('en-IN') : "[.]"}</strong>.
    </p>` : `<p>
      ${ipoDetails?.IssuerCompany} lot size and the minimum order quantity is not available at this time. Please check again later.
    </p>`}
  </div>`
  },
  {
    question: `How to apply for ${ipoDetails?.IssuerCompany}?`,
    answer: `<div>
    <p>
      You can apply in ${ipoDetails?.IssuerCompany} online using either UPI or ASBA as a payment method. ASBA IPO application is available in the net banking of your bank account. UPI IPO application is offered by brokers who don't offer banking services. Read more detail about applying for an IPO online through <a href="https://ipotank.com/broker/zerodha" title="Zerodha IPO Review"><strong>Zerodha</strong></a>, <a href="https://ipotank.com/broker/upstox" title="Upstox IPO Review">Upstox</a>, <a href="https://ipotank.com/broker/5-paisa" title="5Paisa IPO Review">5Paisa</a>, <a href="https://ipotank.com/broker/icici" title="ICICI Bank IPO Review"><strong>ICICI Bank</strong></a> and <a href="https://ipotank.com/broker/hdfc-securities" title="HDFC Bank IPO Review">HDFC Bank</a>.
    </p>
  </div>`
  },
  {
    question: `When is ${ipoDetails?.IssuerCompany} allotment?`,
    answer: `<div>
    <p>
      The finalization of Basis of Allotment for ${ipoDetails?.IssuerCompany} will be done on ${ipoDetails && ipoDetails?.ipo_timeline?.length > 0 && ipoDetails?.ipo_timeline[0]?.data[4] ? ipoDetails?.ipo_timeline[0]?.data[4] :"[.]"}, and the allotted shares will be credited to your demat account by ${ipoDetails && ipoDetails?.ipo_timeline?.length > 0 && ipoDetails?.ipo_timeline[0]?.data[6] ? ipoDetails?.ipo_timeline[0]?.data[6] :"[.]"}. ${ipoDetails && ipoDetails?.ipo_allotment_status?.length > 0 && ipoDetails?.ipo_allotment_status[0]?.data[2] ? `Check the <a href=${ipoDetails && ipoDetails?.ipo_allotment_status?.length > 0 && ipoDetails?.ipo_allotment_status[0]?.data[2]} title="${ipoDetails?.IssuerCompany} Allotment Status">${ipoDetails?.IssuerCompany} allotment status</a>.` : ""}
    </p>
  </div>`
  },
  {
    question: `When is ${ipoDetails?.IssuerCompany} listing date?`,
    answer: `<div><p>The ${ipoDetails?.IssuerCompany} listing date is on <strong>${ipoDetails?.ipo_timeline?.length > 0 && ipoDetails?.ipo_timeline?.[0]?.data?.[7] ? ipoDetails?.ipo_timeline?.[0]?.data?.[7] : "[.]"}</strong>.
  </p></div>`
  }
];



  return (
    <> 
   <Helmet>
   <title>{`${ipoDetails?.IssuerCompany}: Check IPO Date, Price, Subscription, Allotment, GMP`}</title>
   <meta name="description" content={`Get ${ipoDetails?.IssuerCompany} all details. Find IPO Date, Price, Live Subscription, Allotment, Grey Market Premium GMP, Listing Date.`} />
   <meta name="keywords" content={`${ipoDetails?.IssuerCompany}, ${ipoDetails.ipo_static_details && ipoDetails.ipo_static_details.length > 0 && ipoDetails.ipo_static_details[0].data[2]}, Issue Obejctive, IPO details, IPO date, IPO lot size, IPO price, IPO subscription, IPO allotment, IPOtank, IPO tank, Pros & Cons, Company information`} />


   <meta property="og:url" content={`https://www.ipotank.com/ipo/${ipoDetails?.IssuerCompany?.toLowerCase().replace(/ /g, '-')}`} /> <meta property="og:type" content="website" /> <meta property="og:title" content={`${ipoDetails?.IssuerCompany}: Check IPO Date, Price, Subscription, Allotment, GMP`} /> <meta property="og:description" content={`Get ${ipoDetails?.IssuerCompany} all details. Find IPO Date, Price, Live Subscription, Allotment, Grey Market Premium GMP, Listing Date.`} />

</Helmet>
 
   <div className="laptop-view">
<div
    //  className="laptop-view"
        style={{
          display: "flex",
          alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",
          height:"auto",
          padding: "15px 0 5px 0",
          // gap: "5px",
          width:"100%",
          // minWidth:"300px",
          // maxWidth:"320px",
          // borderBottom:"2px solid #bdb4b8",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
          // background: "#dddcdd",
          backgroundColor:"rgb(221, 220, 221)",
          // backgroundColor:"white",
          position:"fixed",
          zIndex: 999,
          // overflowX: "auto",
          // marginTop:"8px",
          // marginLeft:"5px",
          // marginRight:"5px"
        
          // marginRight:"10px"
        //   top: `${navbarHeight}px`, // Position it below the navbar
        // zIndex: "999" // Ensure it appears above other content
         
        }}
      >
      <div onClick={() => scrollToRef(tab1Ref, 'laptop-view')} style={{ minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,marginTop:"10px",marginBottom:"10px",marginLeft:"10px",marginRight:"5px",background: "linear-gradient(90deg, rgba(85,83,117,1) 10%, rgba(103,103,148,1) 35%, rgba(161,123,173,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
      <img src="/list.svg" alt="IPO Details"  style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
      <p
          onClick={() => scrollToRef(tab1Ref, 'laptop-view')}
        //   // onClick={() => handleIpoTypeChange("IPO Details")}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'} 
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
        >IPO Details
      </p>
      </div>
       {/* <div style={{fontSize:"40"}}>|</div> */}
       <div onClick={() => scrollToRef(tab3Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(177,93,25,1) 0%, rgba(222,164,88,1) 100%, rgba(247,197,58,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/subscription.svg" alt="Subscription" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("Subscription")}
          onClick={() => scrollToRef(tab3Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'}  
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >Subscription
        </p></div>
        {/* <div style={{fontSize:"40"}}>|</div> */}
        <div onClick={() => scrollToRef(tab4Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(74,129,60,1) 4%, rgba(116,152,70,1) 47%, rgba(154,167,51,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/notification-bell-svgrepo-com.svg" alt="Allotment" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("Allotment")}
          onClick={() => scrollToRef(tab4Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'} 
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >Allotment
        </p></div>

        {/* <div style={{fontSize:"40"}}>|</div> */}
        {/* {blogs && blogs.length > 0 ? (
        <div onClick={() => scrollToRef(tab2Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/news-paper.svg" alt="News" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("News")}
          onClick={() => scrollToRef(tab2Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'} 
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "Main IPO" ? "red" : "inherit" }}
        >IPO News
        </p></div>):null} */}
        {/* <div style={{fontSize:"40"}}>|</div> */}
        {/* <div onClick={() => scrollToRef(tab3Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(177,93,25,1) 0%, rgba(222,164,88,1) 100%, rgba(247,197,58,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/subscription.svg" alt="Subscription" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("Subscription")}
          onClick={() => scrollToRef(tab3Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'}  
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >Subscription
        </p></div> */}
        {/* <div style={{fontSize:"40"}}>|</div> */}
        {/* <div onClick={() => scrollToRef(tab4Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
        ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/notification-bell-svgrepo-com.svg" alt="Allotment" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("Allotment")}
          onClick={() => scrollToRef(tab4Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'} 
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >Allotment
        </p></div> */}
        {/* <div style={{fontSize:"40"}}>|</div> */}
        
        {/* {ipoDetails.ipo_details && ipoDetails.ipo_details[0]?.data[7] && (pricePerShareRange2 || pricePerShareRange1) ? ( */}
        <div onClick={() => scrollToRef(tab5Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
          ,margin:"10px 5px",background: "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/calculator.svg" alt="Calculator" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("Allotment")}
          onClick={() => scrollToRef(tab5Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'}  
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >Calculator
        </p></div>
       {/* ):null} */}

        {blogs && blogs.length > 0 ? (
        <div onClick={() => scrollToRef(tab2Ref, 'laptop-view')} style={{minWidth:"50px",maxWidth:"100px",width:"100%",display:"flex",flexWrap:"wrap",alignItems: "center",
          // flexDirection: "row",
          justifyContent:"center",flexDirection:"row" ,borderRadius: "10px",padding:"5px 3px"
          ,marginTop:"10px",marginBottom:"10px",marginLeft:"5px",marginRight:"10px",background: "linear-gradient(90deg, rgba(156,55,104,1) 0%, rgba(189,98,156,1) 73%, rgba(207,134,180,1) 100%)",boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5)"
          }}>
        <img src="/news-paper.svg" alt="News" style={{minWidth:"20px",maxWidth:"25px",width:"100%",filter: "brightness(0) invert(1)"}} />
        <p
          // onClick={() => handleIpoTypeChange("News")}
          onClick={() => scrollToRef(tab2Ref, 'laptop-view')}
        //   onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = 'white'} 
        style={{cursor: 'pointer',color:"white" ,fontSize:"10px",textDecoration:"none",height:"auto",textAlign:"center"}}
          // style={{ color: ipoType === "Main IPO" ? "red" : "inherit" }}
        >IPO News
        </p></div>):null}

      </div>    
      <div
     
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
     }}
   > 
   
        {/* <div style={{ width: '95%', maxWidth: '1200px',marginTop:"90px"}}> */}
          
      <div style={{ width: "85%",marginTop:"90px",maxWidth:"1200px",minWidth:"100px",textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"5px"}} >
          <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
          onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
            <p>
              Home
            </p>
          </Link>
          <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        
          <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
          onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
            <p>IPO</p>
          </Link>
          <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
          <Link to={`/ipo/${ipoDetails?.IssuerCompany?.toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
          onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
            <p>{ipoDetails?.IssuerCompany}</p>
          </Link>
        </div>
          {/* </div> */}
          </div>

         <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
      <div style={{padding:"15px 15px",marginTop:"10px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>

 
          
         


  
   <div style={{ display: "flex", justifyContent: "space-between" ,alignItems:"center",marginTop:"-10px",gap:"5px" }}>
   <div style={{ display: "flex", alignItems: "center",gap:"5px"}}>
                       {/* <img src={`../a-z_icons/icons8-${ipoDetails?.IssuerCompany?.charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px"}} /> */}
                       {ipoDetails?.IssuerCompany && (<Avatar  style={{ margin: "0px 5px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{ipoDetails?.IssuerCompany?.charAt(0).toUpperCase()}</Avatar>)} {/* Display the first character inside an avatar */}
                       <h3 style={{userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text' }}>{ipoDetails?.IssuerCompany}</h3>
                       
   <div style={{width:"35px", display: "flex", alignItems: "center",justifyContent:"center",justifyItems:"center",marginLeft:"5px"}}>
   {/* <ShareButton ipoLink={`https://ipotank.com/ipo/${Params["ipo-details"]}`} companyname={Params["ipo-details"]} /> */}
   </div>
   </div>
           {lastValue && lastValue !== '' ? ( 
             <div style={{ textAlign: "center",fontSize:"15px",fontWeight:"bold",border:"1px solid rgb(221, 220, 221)",borderRadius:"10px",padding:"5px 10px" }} >
             Investment / Lot Size
             <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
               <p style={{fontWeight:"light",fontSize:"15px",color:"rgb(189, 98, 156)"}}>₹{lastValue.toLocaleString('en-IN')} / {ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7]}</p>
             </div>
           </div>):null}

          
   </div>
           {/* <div  style={{display:"flex",alignItems:"center",gap:"10px",margin:"0 0"}}> */}
                             {/* <div  style={{color:"white",fontSize:"13px",margin:"5px 0"}}>
                               {ipoDetails && ipoDetails?.ipo_details && ipoDetails?.ipo_details.length > 0 && ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() === "SME" ? 
                               (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(122, 167, 219)",borderRadius:"5px",backgroundColor:"rgb(122, 167, 219)",textDecoration:"none"}}>SME</label>) 
                               :
                               (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(108, 180, 86)",borderRadius:"5px",backgroundColor:"rgb(108, 180, 86)",textDecoration:"none"}}>Mainboard</label>)
                              } 
                            </div> */}
     
                             
                             {/* {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] && ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 && ipoDetails.ipo_timeline[0]?.data[4] && (new Date() >= new Date(ipoDetails.ipo_timeline[0]?.data[4])) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                              <label style={{color:"#dd8f00",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#fdf5e6",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#dd8f00",margin:"1px 5px 0px 0px"}}></div>Allotment Out</label>  </div>)} */}
     
     {/* {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 && ipoDetails.ipo_timeline[0]?.data[4] && ipoDetails?.CloseDate && (new Date() > new Date(ipoDetails?.CloseDate) && new Date() < new Date(ipoDetails.ipo_timeline[0]?.data[4]) ) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                               <label style={{color:"#267ca7",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#daeef7",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#267ca7",margin:"1px 5px 0px 0px"}}></div>Allotment Awaited</label>  </div>)} */}
     {/* {ipoDetails?.ipo_timeline &&
      ipoDetails.ipo_timeline.length > 0 &&
      ipoDetails.ipo_timeline[0]?.data[4] &&
      ipoDetails?.CloseDate && (
        new Date(ipoDetails.CloseDate) < new Date() &&
        new Date() < new Date(ipoDetails.ipo_timeline[0]?.data[4])
      ) && (
        <div style={{ fontSize: "14px", margin: "10px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
        <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
        Allotment Awaited
        </label>
        </div>
      )} */}
     
     
                               {/* {ipoDetails?.OpenDate && ipoDetails?.CloseDate && (
                                 (new Date() === new Date(ipoDetails?.OpenDate)) ||
                                 (new Date() === new Date(ipoDetails?.CloseDate))  ||
                                 ((new Date() >= new Date(ipoDetails?.OpenDate)) && (new Date() <= new Date(ipoDetails?.CloseDate)) )) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                                <label style={{color:"#e13803",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#ffe9eb",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#e13803",margin:"1px 5px 0px 0px"}}></div>Live</label>  </div>)} */}
     
     
     
     
                         
     
                               {/* <div  style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"5px",borderRadius:"5px",backgroundColor:"#f9e9e4",padding:"2px 10px"}}>
                                {ipoDetails && ipoDetails?.ipo_details && ipoDetails?.ipo_details.length > 0 && ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() !== "SME" && 
                               (<><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#e13803",display:"flex",alignItems:"center",justifyContent:"center"}}></div><div style={{color:"#e13803",borderRadius:"5px",backgroundColor:"#f9e9e4",textDecoration:"none"}}>Live</div></>)}
                              </div> */}
     
                           {/* </div> */}
     
                         
      
     
                <div style={{ display: "flex", gap:"10px", alignItems: "center"}}>
                              <div  style={{color:"white",fontSize:"13px",margin:"5px 0"}}>
                                      {ipoDetails && ipoDetails?.ipo_details && ipoDetails?.ipo_details.length > 0 && ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() === "SME" ? 
                                      (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(122, 167, 219)",borderRadius:"5px",backgroundColor:"rgb(122, 167, 219)",textDecoration:"none"}}>SME</label>) 
                                      :
                                      (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(108, 180, 86)",borderRadius:"5px",backgroundColor:"rgb(108, 180, 86)",textDecoration:"none"}}>Mainboard</label>)
                                    } 
                                    </div>
                                    <IPOStatusComponent 
        openDate={new Date(ipoDetails?.OpenDate)} 
        closeDate={new Date(ipoDetails?.CloseDate)} 
        allotmentDate={ipoDetails?.ipo_timeline?.length > 0 ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) : null} 
        isAllotmentOut={ipoDetails?.ipo_allotment_status && ipoDetails?.ipo_allotment_status.length > 0 && ipoDetails?.ipo_allotment_status[0].data[2]} 
        ipoName={Params["ipo-details"]}
        listingDate={new Date(ipoDetails?.ListingDate)}  
      />


                        {/* {ipoDetails?.OpenDate && ipoDetails?.CloseDate && (() => {
                        const currentDate = new Date();
                        

              
                        const openDate = new Date(ipoDetails.OpenDate);
                        const closeDate = new Date(ipoDetails.CloseDate);
                        
                        return (
                        currentDate.toDateString() === openDate.toDateString() ||
                        currentDate.toDateString() === closeDate.toDateString() ||
                        (currentDate >= openDate && currentDate <= closeDate)
                        );
                        })() && (
                        <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
                        <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
                        <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#e13803", margin: "1px 5px 0px 0px" }}></div>
                        Live
                        </label>
                        </div>
                        )}
                        
                        {ipoDetails?.CloseDate && ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 && (() => {
                       const currentDate = stripTime(new Date());
                       const closedDate = stripTime(new Date(ipoDetails?.CloseDate));
                       const allotmentDate = ipoDetails?.ipo_timeline?.length > 0 ? stripTime(new Date(ipoDetails?.ipo_timeline[0]?.data[4])) : null;
                       // const currentDate = new Date();
                       // // const openDate = new Date(ipoDetails.OpenDate);
                       // const closedDate = new Date(ipoDetails?.CloseDate);
                       // const allotmentDate = ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) : null;
                        return (
                        closedDate < currentDate && currentDate < allotmentDate
                        );
                        })() ? (
                        <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
                        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
                        <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
                        Allotment Awaited
                        </label>
                        </div>
                        ):(<div>{displayElement}</div>)} */}
                        
                        </div>
  <div style={{ display: "flex", gap:"20px", alignItems: "center", justifyContent: "space-between"}}>

<div style={{ display: "flex", gap:"20px", alignItems: "center"}}>

                  {ipoDetails?.ListingDate && new Date(ipoDetails?.ListingDate) <= new Date() ? (<IPOComponent  targetString={ipoDetails?.IssuerCompany} ipoPerformanceData={allIPOsPerformance} row11={ipoDetails?.ipo_gmp[0]?.data[4]} row12={ipoDetails?.ipo_gmp[0]?.data[6]} />):( <>{ipoDetails?.ipo_gmp && ipoDetails?.ipo_gmp.length > 0 && ipoDetails?.ipo_gmp[0]?.data[4] !== ('' || null) && (<div style={{display: "flex", alignItems: "center",fontSize:"14px",color:"#888",textAlign:"start", margin: "5px 0"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{ipoDetails?.ipo_gmp[0]?.data[4] < 0 ? (<div style={{color:'red'}}> {ipoDetails?.ipo_gmp[0]?.data[4]} {ipoDetails?.ipo_gmp[0]?.data[6].split(' ')[1]}</div>):(<div style={{color:'green'}}> {ipoDetails?.ipo_gmp[0]?.data[4]} {ipoDetails?.ipo_gmp[0]?.data[6].split(' ')[1]}</div>)}
</div>)}</> )}

{ipoDetails?.ipo_total_application && ipoDetails?.ipo_total_application?.length > 0 ? (<div style={{display: "flex", alignItems: "center",fontSize:"14px",color:"#888",textAlign:"start", margin: "5px 0"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
{ipoDetails?.ipo_total_application[0]?.data[2] !== null && ipoDetails?.ipo_total_application[0]?.data[2].includes('(') ? <div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888",marginRight:"5px"}}>Subs:</div></div> : <div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"#888",marginRight:"5px"}}>Applications:</div></div>}
{/* <div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888",marginRight:"5px"}}>Subs:</div></div> */}
<div>  {(ipoDetails?.ipo_subscription_status[0]?.data[11] !== null) ?( 
   <div style={{color:"rgb(107, 128, 179)"}}>
     {/* {ipoDetails?.ipo_total_application[0]?.data[2].includes('(') ? `${ipoDetails?.ipo_total_application[0]?.data[2].split('(')[1].replace("(",'').replace(")",'')}` : `${ipoDetails?.ipo_total_application[0]?.data[2].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
     {ipoDetails?.ipo_subscription_status[0]?.data[11] && JSON.parse(ipoDetails?.ipo_subscription_status[0]?.data[11])["Subscription (times)"]} times
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}
     </div>
    ): <div style={{color:"rgb(107, 128, 179)"}}>
       ------
     </div>}</div></div>): null}


     </div>  


                {/* {lastValue && lastValue !== '' ? ( 
                  <div style={{ textAlign: "center",fontSize:"14px",fontWeight:"bold",border:"1px solid rgb(221, 220, 221)",borderRadius:"10px",padding:"5px 10px" }} >
                  Investment / Lot Size
                  <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                    <p style={{fontWeight:"light",fontSize:"14px",color:"rgb(189, 98, 156)"}}>₹{lastValue.toLocaleString('en-IN')} / {ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7]}</p>
                  </div>
                </div>):null} */}
           {/* <div>   */}
       {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] && (
  <>
  {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2]}  target="_blank" style={{textDecoration: "none" }} title={`${ipoDetails?.IssuerCompany} Allotment Status`}>
  {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2] !== null && (<button className="animated-button">Check Allotment</button>)}</Link></div> */}
  <div style={{textDecoration: "none",color: "#ffffff"}} title={`${ipoDetails?.IssuerCompany} Allotment Status`}><div onClick={() => handleClick(ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2])} style={{ margin: "5px 0",fontSize:"14px",padding:"10px 10px",width:"auto",color: "#ffffff",animation: "colorChange 2s infinite alternate",backgroundColor:"green",borderRadius:"10px",textAlign:"center"}}>
                Check Allotment
            </div></div>
  </>
  )}
     </div>
               
        {/* </div> */}
                  <div style={{fontSize:"11px",color:"#666",textAlign:"justify",margin:"5px 0 0 0"}}>*Expected Premium provided in this app is derived from market rumors. It is not a guaranteed indicator of the actual listing price and should be interpreted with caution.</div>
             
   {/* <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3>
   <label style={{marginTop:"-10px"}}>Invest in companies listing on the Indian exchanges with an IPO</label> */}
   </div>
   </div>
   </div>



<div className="phone-view">
      <div
            // className="phone-view"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    // padding: "5px 0 5px 0",
    width: "100%",
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 1)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
    // backgroundColor: "rgb(221, 220, 221)",
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    zIndex: 999,
  }}
>
  <div
    onClick={() => {
      scrollToRef(tab1Ref, 'phone-view');
      setactive("details"); // Correctly set the active tab
      setIsButtonClicked(true); // Mark as clicked
    }}
    style={{
      background:active==="details"?"linear-gradient(90deg, rgba(85,83,117,1) 10%, rgba(103,103,148,1) 35%, rgba(161,123,173,1) 100%)":"white",
      minWidth: "50px",
      maxWidth: "100px",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      // borderRadius: "10px",
      padding: "8px 5px",
      // marginTop: "10px",
      // marginBottom: "10px",
      // marginLeft: "10px",
      // marginRight: "5px",
      // background: "linear-gradient(90deg, rgba(85,83,117,1) 10%, rgba(103,103,148,1) 35%, rgba(161,123,173,1) 100%)",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    }}
  >
    <img
      src="/list.svg"
      alt="IPO Details"
      style={{
        minWidth: "20px",
        maxWidth: "25px",
        width: "100%",
        filter: active==="details"? "brightness(0) invert(1)": "brightness(0) invert(1) sepia(1) saturate(1000%) hue-rotate(180deg) brightness(0.6)"
      }}
    />
    <p
      onClick={() => scrollToRef(tab1Ref, 'phone-view')}
      style={{
        cursor: "pointer",
        color: active==="details"? "white":"#555",
        fontSize: "10px",
        textDecoration: "none",
        height: "auto",
        textAlign: "center",
        margin:"0px 0px"
      }}
    >Details
    </p>
  </div>
  <div
    onClick={() => {scrollToRef(tab3Ref, 'phone-view');
      setactive("subscription"); // Correctly set the active tab
      setIsButtonClicked(true); // Mark as clicked
    }}
    style={{
      minWidth: "50px",
      maxWidth: "100px",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      // borderRadius: "10px",
      padding: "8px 5px",
      // margin: "10px 5px",
      background: active==="subscription"? "linear-gradient(90deg, rgba(177,93,25,1) 0%, rgba(222,164,88,1) 100%, rgba(247,197,58,1) 100%)":"white",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    }}
  >
    <img
      src="/subscription.svg"
      alt="Subscription"
      style={{
        minWidth: "20px",
        maxWidth: "25px",
        width: "100%",
        // filter: "brightness(0) invert(1)",
        filter: active==="subscription"? "brightness(0) invert(1)": "brightness(0) invert(1) sepia(1) saturate(1000%) hue-rotate(180deg) brightness(0.6)"
      }}
    />
    <p
      onClick={() => scrollToRef(tab3Ref, 'phone-view')}
      style={{
        cursor: "pointer",
        color: active==="subscription"? "white":"#555",
        fontSize: "10px",
        textDecoration: "none",
        height: "auto",
        textAlign: "center",
        margin:"0px 0px"
      }}
    >
      Subscription
    </p>
  </div>
  <div
    onClick={() => {scrollToRef(tab4Ref, 'phone-view');
      setactive("allotment"); // Correctly set the active tab
      setIsButtonClicked(true); // Mark as clicked
      }}
    style={{
      minWidth: "50px",
      maxWidth: "100px",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      // borderRadius: "10px",
      padding: "8px 5px",
      // margin: "10px 5px",
      background: active==="allotment"? "linear-gradient(90deg, rgba(74,129,60,1) 4%, rgba(116,152,70,1) 47%, rgba(154,167,51,1) 100%)":"white",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    }}
  >
    <img
      src="/notification-bell-svgrepo-com.svg"
      alt="Allotment"
      style={{
        minWidth: "20px",
        maxWidth: "25px",
        width: "100%",
        // filter: "brightness(0) invert(1)",
        filter: active==="allotment"? "brightness(0) invert(1)": "brightness(0) invert(1) sepia(1) saturate(1000%) hue-rotate(180deg) brightness(0.6)"
      }}
    />
    <p
      onClick={() => scrollToRef(tab4Ref, 'phone-view')}
      style={{
        cursor: "pointer",
        color: active==="allotment"? "white":"#555",
        fontSize: "10px",
        textDecoration: "none",
        height: "auto",
        textAlign: "center",
        margin:"0px 0px"
      }}
    >
      Allotment
    </p>
  </div>
  {/* {ipoDetails.ipo_details && ipoDetails.ipo_details[0]?.data[7] && (pricePerShareRange2 || pricePerShareRange1) ? ( */}
    <div
      onClick={() => {scrollToRef(tab5Ref, 'phone-view');
        setactive("calculator"); // Correctly set the active tab
        setIsButtonClicked(true); // Mark as clicked
        }}
      style={{
        minWidth: "50px",
        maxWidth: "100px",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // borderRadius: "10px",
        padding: "8px 5px",
        // margin: "10px 5px",
        background: active==="calculator"? "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)":"white",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
      }}
    >
      <img
        src="/calculator.svg"
        alt="Calculator"
        style={{
          minWidth: "20px",
          maxWidth: "25px",
          width: "100%",
          // filter: "brightness(0) invert(1)",
          filter: active==="calculator"? "brightness(0) invert(1)": "brightness(0) invert(1) sepia(1) saturate(1000%) hue-rotate(180deg) brightness(0.6)"
        }}
      />
      <p
        onClick={() => scrollToRef(tab5Ref, 'phone-view')}
        style={{
          cursor: "pointer",
          color: active==="calculator"? "white":"#555",
          fontSize: "10px",
          textDecoration: "none",
          height: "auto",
          textAlign: "center",
          margin:"0px 0px"
        }}
      >
        Calculator
      </p>
    </div>
  {/* // ) : null} */}
  {/* {blogs && blogs.length > 0 ? ( */}
    <div
      onClick={() => {scrollToRef(tab2Ref, 'phone-view');
        setactive("news"); // Correctly set the active tab
        setIsButtonClicked(true); // Mark as clicked
        }}
      style={{
        minWidth: "50px",
        maxWidth: "100px",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // borderRadius: "10px",
        padding: "8px 5px",
        // marginTop: "10px",
        // marginBottom: "10px",
        // marginLeft: "5px",
        // marginRight: "10px",
        background: active==="news"? "linear-gradient(90deg, rgba(156,55,104,1) 0%, rgba(189,98,156,1) 73%, rgba(207,134,180,1) 100%)":"white",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
      }}
    >
      <img
        src="/news-paper.svg"
        alt="News"
        style={{
          minWidth: "20px",
          maxWidth: "25px",
          width: "100%",
          // filter: "brightness(0) invert(1)",
          filter: active==="news"? "brightness(0) invert(1)": "brightness(0) invert(1) sepia(1) saturate(1000%) hue-rotate(180deg) brightness(0.6)"
        }}
      />
      <p
        onClick={() => scrollToRef(tab2Ref, 'phone-view')}
        style={{
          cursor: "pointer",
          color: active==="news"? "white":"#555",
          fontSize: "10px",
          textDecoration: "none",
          height: "auto",
          textAlign: "center",
          margin:"0px 0px"
        }}
      >News
      </p>
    </div>
  {/* ) : null} */}
</div>

<div
     
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
     }}
   > 
    {/* <br/>
   <br/>
   <br/> */}
        {/* <div style={{ width: '95%', maxWidth: '1200px'}}> */}
        {/* <div style={{justifyItems:"left",display: "flex"}}> */}

        {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{  width: '90%',maxWidth:"1200px",minWidth:"100px",textAlign: 'left' ,display: "flex",alignItems: "left", gap: "10px", color:"#666",paddingTop:"5px"}} >
     <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
       <p>
         Home
       </p>
     </Link>
     <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
   
     <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
       <p>IPO</p>
     </Link>
     <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
     <Link title={ipoDetails?.IssuerCompany} to={`/ipo/${ipoDetails?.IssuerCompany?.toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
       <p> {ipoDetails?.IssuerCompany?.split(' ').length > 3 
    ? ipoDetails?.IssuerCompany?.split(' ').slice(0, 3).join(' ') + '...' 
    : ipoDetails?.IssuerCompany}
    </p>
     </Link>
   </div>)}

   {(deviceInfo === "app" && navigation) && (<div style={{marginBottom:"-10px"}}></div>)}
   {/* </div> */}
   <br/>
 
  
   <div style={{ width: '85%',padding:"15px 15px",marginTop:"-15px",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        
   {/* <div style={{ display: "flex", justifyContent: "space-between" ,alignItems:"center"}}> */}

   <div style={{ display: "flex", alignItems: "center"}}>
                       {/* <img src={`../a-z_icons/icons8-${ipoDetails?.IssuerCompany?.charAt(0).toLowerCase()}-64.png`} alt="" width="50px" height="50px" style={{ marginRight: "0px"}} /> */}
                       {ipoDetails?.IssuerCompany && (<Avatar  style={{ margin: "0px 10px 0px 5px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{ipoDetails?.IssuerCompany?.charAt(0).toUpperCase()}</Avatar>)} {/* Display the first character inside an avatar */}
                       <div style={{ display: "flex", alignItems: "center",gap:"10px"}}>
                      <div style={{fontSize:"1rem",fontWeight:"bold",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text' }}>{ipoDetails?.IssuerCompany} {/* <span> */}</div>
                        {/* </span>  */}
                        {/* <span></span> */}
                        <div style={{width:"35px", display: "flex", alignItems: "center"}}>
   {/* <ShareButton ipoLink={`https://ipotank.com/ipo/${Params["ipo-details"]}`} companyname={Params["ipo-details"]} /> */}
   </div>   
                        </div>


   </div>
   <div  style={{display:"flex",alignItems:"center",gap:"10px",margin:"0 0"}}>
                        <div  style={{color:"white",fontSize:"13px",margin:"5px 0"}}>
                          {ipoDetails && ipoDetails?.ipo_details && ipoDetails?.ipo_details.length > 0 && ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() === "SME" ? 
                          (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(122, 167, 219)",borderRadius:"5px",backgroundColor:"rgb(122, 167, 219)",textDecoration:"none"}}>SME</label>) 
                          :
                          (<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid rgb(108, 180, 86)",borderRadius:"5px",backgroundColor:"rgb(108, 180, 86)",textDecoration:"none"}}>Mainboard</label>)
                        } 
                        </div>

                        
                        {/* {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] && ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 && ipoDetails.ipo_timeline[0]?.data[4] && (new Date() >= new Date(ipoDetails.ipo_timeline[0]?.data[4])) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                         <label style={{color:"#dd8f00",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#fdf5e6",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#dd8f00",margin:"1px 5px 0px 0px"}}></div>Allotment Out</label>  </div>)} */}

{/* {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 && ipoDetails.ipo_timeline[0]?.data[4] && ipoDetails?.CloseDate && (new Date() > new Date(ipoDetails?.CloseDate) && new Date() < new Date(ipoDetails.ipo_timeline[0]?.data[4]) ) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                          <label style={{color:"#267ca7",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#daeef7",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#267ca7",margin:"1px 5px 0px 0px"}}></div>Allotment Awaited</label>  </div>)} */}
{/* {ipoDetails?.ipo_timeline &&
 ipoDetails.ipo_timeline.length > 0 &&
 ipoDetails.ipo_timeline[0]?.data[4] &&
 ipoDetails?.CloseDate && (
  new Date(ipoDetails.CloseDate) < new Date() &&
  new Date() < new Date(ipoDetails.ipo_timeline[0]?.data[4])
 ) && (
  <div style={{ fontSize: "14px", margin: "10px 0", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
      <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
      Allotment Awaited
    </label>
  </div>
)} */}


                          {/* {ipoDetails?.OpenDate && ipoDetails?.CloseDate && (
                            (new Date() === new Date(ipoDetails?.OpenDate)) ||
    (new Date() === new Date(ipoDetails?.CloseDate))  ||
    ((new Date() >= new Date(ipoDetails?.OpenDate)) && (new Date() <= new Date(ipoDetails?.CloseDate)) )) && (<div  style={{fontSize:"14px",margin:"10px 0",display:"flex",alignItems:"center",gap:"10px"}}>
                          <label style={{color:"#e13803",padding:"2px 10px",borderRadius:"5px",backgroundColor:"#ffe9eb",textDecoration:"none",display:"flex",alignItems:"center",width:"auto"}}><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#e13803",margin:"1px 5px 0px 0px"}}></div>Live</label>  </div>)} */}

                         {/* <getIPOCurrentStatus openDate={ipoDetails?.openDate} closeDate={ipoDetails?.CloseDate} allotmentDate={ipoDetails?.ipo_timeline.length >0 && ipoDetails?.ipo_timeline[0]?.data[4]} allotmentHref={ipoDetails?.ipo_allotment_status.length >0 && ipoDetails?.ipo_allotment_status[0].data[2]} /> */}
                         {/* {status} */}
                       
{/* {ipoDetails?.OpenDate && ipoDetails?.CloseDate && (() => {
  const currentDate = new Date();
  const openDate = new Date(ipoDetails.OpenDate);
  const closeDate = new Date(ipoDetails.CloseDate);

  return (
    currentDate.toDateString() === openDate.toDateString() ||
    currentDate.toDateString() === closeDate.toDateString() ||
    (currentDate >= openDate && currentDate <= closeDate)
  );
})() && (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
      <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#e13803", margin: "1px 5px 0px 0px" }}></div>
      Live
    </label>
  </div>
)}

{ipoDetails?.CloseDate && ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 && (() => {
  const currentDate = stripTime(new Date());
  const closedDate = stripTime(new Date(ipoDetails?.CloseDate));
  const allotmentDate = ipoDetails?.ipo_timeline?.length > 0 ? stripTime(new Date(ipoDetails?.ipo_timeline[0]?.data[4])) : null;
  // const currentDate = new Date();
  // // const openDate = new Date(ipoDetails.OpenDate);
  // const closedDate = new Date(ipoDetails?.CloseDate);
  // const allotmentDate = ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) : null;
  return (
    closedDate < currentDate && currentDate < allotmentDate
  );
})() ? (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Allotment Awaited
        </label>
      </div>
):(<div>{displayElement}</div>)} */}

      <IPOStatusComponent 
        openDate={new Date(ipoDetails?.OpenDate)} 
        closeDate={new Date(ipoDetails?.CloseDate)} 
        allotmentDate={ipoDetails?.ipo_timeline?.length > 0 ? new Date(ipoDetails?.ipo_timeline[0]?.data[4]) : null} 
        isAllotmentOut={ipoDetails?.ipo_allotment_status && ipoDetails?.ipo_allotment_status.length > 0 && ipoDetails?.ipo_allotment_status[0].data[2]} 
        ipoName={Params["ipo-details"]}
        listingDate={new Date(ipoDetails?.ListingDate)}
      />



                    

                          {/* <div  style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"5px",borderRadius:"5px",backgroundColor:"#f9e9e4",padding:"2px 10px"}}>
                           {ipoDetails && ipoDetails?.ipo_details && ipoDetails?.ipo_details.length > 0 && ipoDetails?.ipo_details[0]?.data[13]?.split(" ").pop() !== "SME" && 
                          (<><div style={{width:"8px",height:"8px",borderRadius:"100%",backgroundColor:"#e13803",display:"flex",alignItems:"center",justifyContent:"center"}}></div><div style={{color:"#e13803",borderRadius:"5px",backgroundColor:"#f9e9e4",textDecoration:"none"}}>Live</div></>)}
                      </div> */}

                      </div>

                    
                      {/* <div style={{display: "flex", gap:"10px", alignItems: "center",flexWrap:"wrap"}}> */}
                       {ipoDetails?.ListingDate && new Date(ipoDetails?.ListingDate) <= new Date() ? (<div style={{margin: "5px 0"}}><IPOComponent  targetString={ipoDetails?.IssuerCompany} ipoPerformanceData={allIPOsPerformance} row11={ipoDetails?.ipo_gmp[0]?.data[4]} row12={ipoDetails?.ipo_gmp[0]?.data[6]} /></div>):( <>{ipoDetails?.ipo_gmp && ipoDetails?.ipo_gmp.length > 0 && ipoDetails?.ipo_gmp[0]?.data[4] !== ('' || null) && (<div style={{display:"flex",flexDirection:"row",alignItems:"center",fontSize:"14px",color:"#888",textAlign:"start", margin: "5px 0"}}
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{ipoDetails?.ipo_gmp[0]?.data[4] < 0 ? (<div style={{color:'red'}}> {ipoDetails?.ipo_gmp[0]?.data[4]} {ipoDetails?.ipo_gmp[0]?.data[6].split(' ')[1]}</div>):(<div style={{color:'green'}}> {ipoDetails?.ipo_gmp[0]?.data[4]} {ipoDetails?.ipo_gmp[0]?.data[6].split(' ')[1]}</div>)}
</div>)}</> )}

{ipoDetails?.ipo_total_application && ipoDetails?.ipo_total_application?.length > 0 ? (<div style={{display: "flex", alignItems: "center",fontSize:"14px",color:"#888",textAlign:"start", margin: "5px 0"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
{ipoDetails?.ipo_total_application[0]?.data[2] !== null && ipoDetails?.ipo_total_application[0]?.data[2].includes('(') ? <div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888",marginRight:"5px"}}>Subs:</div></div> : <div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"#888",marginRight:"5px"}}>Applications:</div></div>}
{/* <div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888",marginRight:"5px"}}>Subs:</div></div> */}
<div>  {(ipoDetails?.ipo_subscription_status[0]?.data[11] !== null) ?( 
   <div style={{color:"rgb(107, 128, 179)"}}>
     {/* {ipoDetails?.ipo_total_application[0]?.data[2].includes('(') ? `${ipoDetails?.ipo_total_application[0]?.data[2].split('(')[1].replace("(",'').replace(")",'')}` : `${ipoDetails?.ipo_total_application[0]?.data[2].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
     {ipoDetails?.ipo_subscription_status[0]?.data[11] && JSON.parse(ipoDetails?.ipo_subscription_status[0]?.data[11])["Subscription (times)"]} times
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}
     </div>
    ): <div style={{color:"rgb(107, 128, 179)"}}>
       ------
     </div>}</div></div>): null}


           <div style={{ display: "flex", gap:"10px", alignItems: "center",marginTop:"5px 0px 0px 0px"}}>
           {lastValue && lastValue !== '' ? ( 
             <div style={{ textAlign: "center",fontSize:"14px",fontWeight:"bold",border:"1px solid rgb(221, 220, 221)",borderRadius:"10px",padding:"5px 10px",marginTop:"5px" }} >
             Investment / Lot Size
             <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
               <p style={{fontWeight:"light",fontSize:"14px",color:"rgb(189, 98, 156)"}}>₹{lastValue.toLocaleString('en-IN')} / {ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7]}</p>
             </div>
           </div>):null}
          
           {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] && (
  <>
   {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2]}  target="_blank" style={{textDecoration: "none" }} title={`${ipoDetails?.IssuerCompany} Allotment Status`}>
 {ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2] !== null && (<button className="animated-button">Check Allotment</button>)}</Link></div> */}
     <div style={{textDecoration: "none",color: "#ffffff"}} title={`${ipoDetails?.IssuerCompany} Allotment Status`}><div onClick={() => handleClick(ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2])} style={{fontSize:"14px",padding:"10px 10px",width:"auto",color: "#ffffff",animation: "colorChange 2s infinite alternate",backgroundColor:"green",borderRadius:"10px",textAlign:"center"}}>
                    Check Allotment
                </div></div>
  </>
)}
</div>
          
   {/* </div> */}
             <div style={{fontSize:"11px",color:"#666",textAlign:"justify",margin:"5px 0 0 0"}}>*Expected Premium provided in this app is derived from market rumors. It is not a guaranteed indicator of the actual listing price and should be interpreted with caution.</div>
        </div>
   {/* <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3>
   <label style={{marginTop:"-10px"}}>Invest in companies listing on the Indian exchanges with an IPO</label> */}
   {/* </div> */}
   </div>
</div>


<br/>
    {ipoDetails?.ipo_static_details && ipoDetails?.ipo_static_details?.length > 0  && ipoDetails?.ipo_static_details[0].data[7] && ( <>
  <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize:"14px"
        }}
      >  <div  style={{marginTop:"0px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0",color:"black"}}>About Company</h2>
        {/* <div style={contentStyle} dangerouslySetInnerHTML={{ __html: ipoDetails?.ipo_static_details[0].data[7] }} /> */}
        <div>
        
        <div style={contentStyle} dangerouslySetInnerHTML={{ __html: ipoDetails?.ipo_static_details[0].data[7] }} />
      <div style={{color:"#007BFF",padding:"5px 0",fontSize:"14px"}}     onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                  onMouseLeave={(e) => e.target.style.color = '#007BFF'} onClick={toggleReadMore}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </div>
    </div>
        </div></div>
    <br/></>)}

    {transformedObject && ipoDetails && ipoDetails?.ipo_subscription_status && ipoDetails?.ipo_subscription_status?.length > 0 && ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline.length > 0 && (() => {
  const currentDate = new Date();
  const ipoStartDate = new Date(ipoDetails?.ipo_timeline?.[0]?.data?.[2]);
  const ipoEndDate = new Date(ipoDetails?.ipo_timeline?.[0]?.data?.[3]);

  // Normalize dates to avoid time differences affecting the comparison
  const isToday = currentDate.toDateString() === ipoEndDate.toDateString();
  const isWithinRange = currentDate >= ipoStartDate && currentDate <= ipoEndDate;

  return ((isToday && ipoDetails?.ipo_subscription_status?.length > 0) || isWithinRange) && transformedObject && ipoDetails?.ipo_subscription_status?.length > 0 ? (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            marginTop: "0px",
            width: "85%",
            maxWidth: "1200px",
            minWidth: "100px",
            height: "auto",
          }}
        >
          <h2 style={{ margin: "0", color: "black" }}>IPO Live Subscription Details (times)</h2>

          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '16px',
              backgroundColor: '#fff',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              margin: '16px 0 10px 0',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flex: '1 1 45%',
                padding: '3px 0',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                minWidth: '250px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '14px', minWidth: "160px" }}>Date</span>
              <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>:</span>
              <span style={{ fontSize: '14px', color: '#333' }}>
                {`${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`}
              </span>
            </div>
            {transformedObject.columns.map((column, index) => (
              <div
                key={column}
                style={{
                  flex: '1 1 45%',
                  padding: '3px 0',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  minWidth: '250px',
                }}
              >
                <span style={{ fontWeight: 'bold', fontSize: '14px', minWidth: "160px" }}>
                  {column
                    .replace(/_/g, " ")
                    .replace('NonInstitutional Buyers', 'Non-Institutional Buyers')
                    .replace(' star', '*')
                    .replace('bNII bids above 10L', 'bNII (bids above ₹10L)')
                    .replace('sNII bids below 10L', 'sNII (bids below ₹10L)')
                    .replace("Qualified Institutions", "QIB")
                    .replace("Non-Institutional Buyers", "NII")
                    .replace("Non-Institutional Buyers*", "NII*")}
                </span>
                <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>:</span>
                <span style={{ fontSize: '14px', color: '#333' }}>
                  {transformedObject.data[index].replace("[.]", "----")}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div><br/>
    </>
  ) : null;
})()}


    {/* {ipoDetails?.ipo_timeline && ipoDetails?.ipo_timeline.length > 0 && ((new Date() === new Date(ipoDetails?.ipo_timeline?.[0]?.data?.[3])) || (new Date() >= new Date(ipoDetails?.ipo_timeline?.[0]?.data?.[2]) && new Date() <= new Date(ipoDetails?.ipo_timeline?.[0]?.data?.[3]))) && transformedObject && ( <>
  <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize:"14px"
        }}
      >  <div  style={{marginTop:"0px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>
        <h2 style={{margin:"0",color:"black"}}>IPO Live Subscription Details (times)</h2>
       
        
       

<div style={{
      border: '1px solid #ddd',
      borderRadius: '4px',
      padding: '16px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '16px 0 10px 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }}>
       <div style={{
        flex: '1 1 45%', 
        padding: '3px 0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        minWidth: '250px'
      }}>
        <span style={{ fontWeight: 'bold', fontSize: '14px',minWidth:"160px" }}>Date</span>
        <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>:</span>
        <span style={{ fontSize: '14px', color: '#333' }}>{`${String(new Date().getDate()).padStart(2, '0')}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${new Date().getFullYear()}`}</span>
      </div>
      {transformedObject.columns.map((column, index) => (
      <div  key={column} style={{
        flex: '1 1 45%', 
        padding: '3px 0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        minWidth: '250px'
      }}>
        <span style={{ fontWeight: 'bold', fontSize: '14px',minWidth:"160px" }}>{column.replace(/_/g, " ").replace('NonInstitutional Buyers','Non-Institutional Buyers').replace(' star','*').replace('bNII bids above 10L','bNII (bids above ₹10L)').replace('sNII bids below 10L','sNII (bids below ₹10L)').replace("Qualified Institutions","QIB").replace("Non-Institutional Buyers","NII").replace("Non-Institutional Buyers*","NII*")}</span>
        <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>:</span>
        <span style={{ fontSize: '14px', color: '#333' }}>{transformedObject.data[index].replace("[.]","----")}</span>
      </div> ))}

     

    </div>
    </div>


 
    </div>
      

   

    <br/></>)} */}
 
      <div
      ref={placeholderRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
      <div ref={tab1Ref} style={{padding:"15px 15px",marginTop:"0px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0"}}>IPO Details</h2>
        {/* <hr /> */}

        <div className="containerinfo" style={{marginTop:"10px"}}>
      {/* Image Div */}
      {ipoDetails.ipo_static_details && ipoDetails.ipo_static_details?.length > 0 && ipoDetails.ipo_static_details[0]?.data[6] !== '' ? (
      <div className="image-div">
        <img
          src={ipoDetails.ipo_static_details[0]?.data[6]}
          alt={ipoDetails?.IssuerCompany}
          className="image"
        />
      </div>):(<div className="image-div">
        <img
          src='/IPOtank1.png'
          alt="Description"
          className="image"
        />
      </div>)}

      {/* Text Div */}
      <div className="text-div">
      <p className="text">
  <p style={{margin:"0px 0px"}}>
  {ipoDetails?.IssuerCompany && ipoDetails?.ipo_details?.[0]?.data?.[2] ? (<>{`The ${ipoDetails?.IssuerCompany} runs from ${ipoDetails?.ipo_details?.[0]?.data?.[2]}.`}</>): null}
  {ipoDetails?.ipo_timeline?.[0]?.data?.[7] ? (<>{` The listing date is ${ipoDetails?.ipo_timeline?.[0]?.data?.[7]}.`}</>) : null}
  {ipoDetails?.ipo_details?.[0]?.data?.[4] ? (<>{` The face value is ${ipoDetails?.ipo_details?.[0]?.data?.[4]}.`}</>) : null}
  {ipoDetails?.ipo_details?.[0]?.data?.[6] ? (<>{` The price band ranges from ${ipoDetails?.ipo_details?.[0]?.data?.[6]}.`}</>) : (ipoDetails?.ipo_details?.[0]?.data?.[5] ? (<>{` The price band range ${ipoDetails?.ipo_details?.[0]?.data?.[5]}.`}</>) : null)}
  {ipoDetails?.ipo_details?.[0]?.data?.[7] ? (<>{` The lot size is ${ipoDetails?.ipo_details?.[0]?.data?.[7]}.`}</>) : null}</p>
  <p style={{margin:"0px 0px"}}>
  {ipoDetails?.ipo_details?.[0]?.data?.[8]  ? (<>{` The total issue size is ${ipoDetails?.ipo_details?.[0]?.data?.[8]}.`}</>) : null}
  {ipoDetails?.ipo_details?.[0]?.data?.[9] ? (<>{` The fresh issue consists of ${ipoDetails?.ipo_details?.[0]?.data?.[9]}.`}</>) : null}
  {ipoDetails?.ipo_details?.[0]?.data?.[10] ? (<>{` The offer for sale includes ${ipoDetails?.ipo_details?.[0]?.data?.[10]}.`}</>) : null}
  {ipoDetails?.ipo_details?.[0]?.data?.[11] ? (<>{` There is an employee discount of ${ipoDetails?.ipo_details?.[0]?.data?.[11]}.`}</>) : null}</p>
  <p style={{margin:"0px 0px"}}>
  {ipoDetails?.ipo_details?.[0]?.data?.[13] ? (<>{` The shares will be listed on ${ipoDetails?.ipo_details?.[0]?.data?.[13].replace(","," and")}.`}</>) : null}
  {ipoDetails?.ipo_timeline?.[0]?.data?.[4] ? (<>{` The basis of allotment will be finalized on ${ipoDetails?.ipo_timeline?.[0]?.data?.[4]}.`}</>) : null}
  {ipoDetails?.ipo_timeline?.[0]?.data?.[5] ? (<>{` Refunds will be initiated on ${ipoDetails?.ipo_timeline?.[0]?.data?.[5]}.`}</>) : null}</p>



</p>


      </div>
    </div>

        <div  style={{padding:"10px 20px", backgroundColor:"#aa336a0d",marginTop:"20px"}}>
          <h3 style={{color:"#666",marginBottom:"20px"}}>{ipoDetails?.IssuerCompany} Highlights</h3>
          <div ref={containerRef}
      // style={{
      //   overflowX: "auto",
      //   overflowY: "hidden",
      //   whiteSpace: "nowrap",
      //   "-ms-overflow-style": "none",
      //    "scrollbar-width": "none" 
      // }}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove} >
            <table>
              <tbody >

             
                    <tr>
 
                    <div  style={{display:"flex",flexWrap:"wrap",gap:"10px",paddingTop:"5px",paddingBottom:"20px"}}>
                    {/* {filteredColumns && filteredColumns.slice(2).map((columnName,index) => {
        // const columnName= ipoDetails.ipo_details[0].columns[index];
        let dataValue = filteredData && filteredData.slice(2)[index];
        // console.log(dataValue)
        // if (dataValue) {

         // Check if the column name is "Listing Date" and set the dataValue accordingly
  if (columnName.replace(/_/g, " ") === "Listing Date") {
    dataValue = ipoDetails.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 && ipoDetails?.ipo_timeline[0]?.data[7];
  }

        if (index < visibleColumns) {
          return (
            <td key={index} style={{ textAlign: "left", border: "1px solid whitesmoke", overflow: "hidden", borderRadius: "10px", boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)", padding: "10px 15px", maxWidth: "245px", minWidth: "auto", width: "100%", alignContent: "center",fontSize:"13px",color:"#666" }}>
              {columnName.replace(/_/g, " ")}
              <div style={{ marginTop: "-10px", marginBottom: "-15px", height: "auto" }}>
                <p style={{ fontWeight: "light", color: "rgb(189, 98, 156)", wordWrap: "break-word", width: "100%", whiteSpace: "pre-wrap" ,fontSize:"15px"}}>{dataValue.split("(aggregating")[0].replace('[.]','----').replace(' [.]',' ----') || "----"}</p>
              </div>
            </td>
          );
        } else {
          return null;
        }
      // } else {
      //   return null;
      // }
      })} */}
      


{filteredColumns && filteredColumns.slice(2).map((columnName, index) => {
  // const columnName = ipoDetails.ipo_details[0].columns[index];
  let dataValue = filteredData && filteredData.slice(2)[index];

  // Check if the column name is "Listing Date" and set the dataValue accordingly
  if (columnName.replace(/_/g, " ") === "Listing Date") {
    dataValue = ipoDetails.ipo_timeline && ipoDetails?.ipo_timeline?.length > 0 && ipoDetails?.ipo_timeline[0]?.data[7];
  }

  // if (dataValue) {
  if (index < visibleColumns) {
    return (
      <td
        key={index}
        style={{
          textAlign: "left",
          border: "1px solid whitesmoke",
          overflow: "hidden",
          borderRadius: "10px",
          boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)",
          padding: "10px 15px",
          maxWidth: "245px",
          minWidth: "auto",
          width: "100%",
          alignContent: "center",
          fontSize: "13px",
          color: "#666"
        }}
      >
        {columnName.replace(/_/g, " ")}
        <div style={{ marginTop: "-10px", marginBottom: "-15px", height: "auto" }}>
          <p
            style={{
              fontWeight: "light",
              color: "rgb(189, 98, 156)",
              wordWrap: "break-word",
              width: "100%",
              whiteSpace: "pre-wrap",
              fontSize: "15px"
            }}
          >
            {dataValue.split("(aggregating")[0].replace('[.]','----').replace(' [.]',' ----') || "----"}
          </p>
        </div>
      </td>
    );
  } else {
    return null;
  }
  // } else {
  //   return null;
  // }
})}



      {/* {ipoDetails && ipoDetails.ipo_details && ipoDetails.ipo_details[0].columns.slice(2).map((columnName, index) => {
        const dataValue = ipoDetails.ipo_details[0].data.slice(2)[index];
        
        if (index < visibleColumns && dataValue) {
          return (
            <td key={index} style={{ textAlign: "left", border: "1px solid whitesmoke", overflow: "hidden", borderRadius: "10px", boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)", padding: "10px 15px", maxWidth: "245px", minWidth: "auto", width: "100%", alignContent: "center", fontSize: "13px" }}>
              {columnName.replace(/_/g, " ")}
              <div style={{ marginTop: "-10px", marginBottom: "-15px", height: "auto" }}>
                <p style={{ fontWeight: "light", color: "#ff7847", wordWrap: "break-word", width: "100%", whiteSpace: "pre-wrap", fontSize: "15px" }}>{dataValue.split("(aggregating")[0] || "----"}</p>
              </div>
            </td>
          );
        } else {
          return null;
        }
      })} */}
    
    
                    {/* {ipoDetails.ipo_details && ipoDetails.ipo_details[0].columns.slice(2).map((columnName, index) => {
                const dataValue = ipoDetails.ipo_details[0].data.slice(2)[index];
                if (dataValue !== "") {
                  return (
                    <td  key={index} style={{ textAlign: "left",border:"1px solid whitesmoke",overflow: "hidden", borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)",padding:"10px 15px",maxWidth:"245px",minWidth:"auto" ,width:"100%",alignContent:"center"}}> */}
                      {/* ,minWidth:"180px",maxWidth:"200px" ,maxWidth:"245px",minWidth:"auto"*/}
                    {/* {columnName.replace(/_/g, " ")}
                      <div style={{marginTop:"-10px",marginBottom:"-15px",height:"auto"}}>
                        <p style={{fontWeight:"light",color:"#666", wordWrap: "break-word",width: "100%", whiteSpace: "pre-wrap"}}>{dataValue || "----"}</p>
                      </div>
                    </td>
                    );
                    } else {
                      return null; // Don't render the row if dataValue is empty
                    }
                  })} */}
                    </div>
                    
                  </tr>
                 
                   
                  
                   
               
                {/* {ipoDetails.ipo_details && ipoDetails.ipo_details[0].map((row, index) => (
                  <tr key={index} >
 
                    <div  style={{display:"flex",flexWrap:"nowrap",gap:"15px",paddingTop:"5px",paddingBottom:"20px"}}>
                   

                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px" ,minWidth:"200px",width:"100%"}}>
                      Issue Date
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[0]}</p>
                      </div>
                    </td> */}
                    {/* <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%" }}>
                      Investment
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[1]}</p>
                      </div>
                    </td>
                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%"}}>
                      Price Range
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[2]}</p>
                      </div>
                    </td>
                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%" }}>
                      Lot Size
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[3]}</p>
                      </div>
                    </td>
                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%" }}>
                      IPO Size
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[4]}</p>
                      </div>
                    </td>
                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%" }}>
                      IPO Size
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{ row[4] || "----"}</p>
                      </div>
                    </td>
                    <td style={{ textAlign: "left",border:"1px solid #666",borderRadius:"10px",padding:"10px 15px",minWidth:"200px",width:"100%" }}>
                      IPO Size
                      <div style={{marginTop:"-10px",marginBottom:"-15px"}}>
                        <p style={{fontWeight:"light",color:"#ff7847"}}>{row[4]}</p>
                      </div>
                    </td> */}
                    {/* </div>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
          {ipoDetails.ipo_details && ipoDetails.ipo_details[0]?.columns.length > 6 &&
        <div style={{color:"#666",marginBottom:"10px",marginTop:"-5px",fontWeight:"bold",fontSize:"15px",textAlign:"center"}}>
          {showAll ?
            <label onMouseEnter={(e) => e.target.style.color = 'rgb(189, 98, 156)'}
            onMouseLeave={(e) => e.target.style.color = '#666'}  onClick={handleShowLess}>-- Show Less --</label> :
            <label onMouseEnter={(e) => e.target.style.color = 'rgb(189, 98, 156)'}
            onMouseLeave={(e) => e.target.style.color = '#666'} onClick={handleViewMore}>-- View More --</label>
          }
        </div>
      }
          </div>
          

          {/* <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Information</h4> */}
        

    {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline.length > 0 ? (
      <>
     <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Timeline</h4>
    
     <IPOTimeline data={ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0]} currentDate={currentDate} />
     </>
    ): null} 
    



    {ipoDetails.ipo_financial_information && ipoDetails.ipo_financial_information.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Financial Information</h4>
       
        <TableContainer component={Paper} >
      <Table sx={{ minWidth: 350 }} aria-label="customized table">
      <caption style={{ textAlign: "right" }}>
                {ipo_financial_information_amountInCaption}
              </caption>
          <TableHead>
        <TableRow>
        {ipo_financial_information_columns.map((columnName, index) => (
                    <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                      {columnName.replace(/_/g, " ")}
                    </StyledTableCell>
                  ))}
            </TableRow>
        </TableHead>
        <TableBody>
        {ipo_financial_information_rowData.map((row, index) => (
                  <StyledTableRow key={index}>
                    {row
                      .filter((i) => i != "")
                      .map((cell, cellIndex) => (
                        <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                          {cell}
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                ))}
        </TableBody>
      </Table>
    </TableContainer>

          {/* <TableContainer
            style={{ width: "90%", maxWidth: "1200px"}}
          >
            <Table sx={{ minWidth: 350 }} aria-label="customized table">
              <caption style={{ textAlign: "right" }}>
                {amountInCaption}
              </caption>
              <TableHead>
                <TableRow>
                  {columns.map((columnName, index) => (
                    <StyledTableCell key={index}>
                      {columnName.replace(/_/g, " ")}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => (
                  <StyledTableRow key={index}>
                    {row
                      .filter((i) => i != "")
                      .map((cell, cellIndex) => (
                        <StyledTableCell key={cellIndex}>
                          {cell}
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}


        </>
    ): null}

    {ipoDetails.ipo_reservation && ipoDetails.ipo_reservation.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Reservation</h4> 
      
         <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_reservation_columns.map((columnName, index) => (
                     <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace('gt ','> ₹').replace('lt ','< ₹').replace('HNI','(HNI)')}
                     </StyledTableCell>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_reservation_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
     </>
    ): null}

    {ipoDetails.ipo_anchor_investors_details && ipoDetails.ipo_anchor_investors_details.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Anchor Investors Details</h4> 
        <TableContainer component={Paper} style={{maxWidth:"700px"}}>
      <Table sx={{ minWidth: 350 }} aria-label="customized table">
        <TableBody>
          {ipoDetails.ipo_anchor_investors_details[0].columns.slice(2).map((columnName, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="left" >
                {columnName.replace(/_/g, " ")
                  .replace('Cr','(In Cr.)')
                  .replace('Anchor LockIn Period 50Percent','Anchor lock-in period end date for 50% shares (30 Days)')
                  .replace('Anchor LockIn Period Remaining','Anchor lock-in period end date for remaining shares (90 Days)')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {ipoDetails.ipo_anchor_investors_details[0].data.slice(2)[index]}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </>
    ) : null}


    {ipoDetails.ipo_lot_size && ipoDetails.ipo_lot_size.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Lot Size</h4> 
        <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_lot_size_columns.map((columnName, index) => (
                     <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace('Min','(Min)').replace('Max','(Max)').replace('S HNI','S-HNI').replace('B HNI','B-HNI')}
                     </StyledTableCell>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_lot_size_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
        </>
    ): null}


    {ipoDetails.ipo_promoter_holding && ipoDetails.ipo_promoter_holding.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Promoter Holding</h4> 
        <TableContainer component={Paper} style={{maxWidth:"400px"}}>
      <Table sx={{ minWidth: 350 }} aria-label="customized table">
        <TableBody>
          {ipoDetails.ipo_promoter_holding[0].columns.slice(2).map((columnName, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="left" >
                {columnName.replace(/_/g, " ")
                  // .replace('Cr','(In Cr.)')
                  // .replace('Anchor LockIn Period 50Percent','Anchor lock-in period end date for 50% shares (30 Days)')
                  // .replace('Anchor LockIn Period Remaining','Anchor lock-in period end date for remaining shares (90 Days)')
                  }
              </StyledTableCell>
              <StyledTableCell align="left">
                {ipoDetails.ipo_promoter_holding[0].data.slice(2)[index]}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </>
    ): null}



{ipoDetails.ipo_key_performance_indicator && ipoDetails.ipo_key_performance_indicator.length > 0 && ipoDetails.pre_post_ipo && ipoDetails.pre_post_ipo.length > 0 ? (
  <>
    <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Key Performance Indicator</h4>
    {/* <div style={{display:"flex", flexWrap:"wrap", alignContent:"center", marginBottom:"15px"}}> */}
      {/* <div style={{marginBottom:"25px"}}> */}
        <TableContainer component={Paper} style={{marginBottom:"25px",maxWidth:"400px"}}>
          <Table sx={{ minWidth: 350 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">KPI</StyledTableCell>
                <StyledTableCell align="left">Values</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ipoDetails.ipo_key_performance_indicator[0].columns.slice(2).map((columnName, index) => {
                const dataValue = ipoDetails.ipo_key_performance_indicator[0].data.slice(2)[index];
                if (dataValue !== "") {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" style={{fontWeight:"bold", color:"#666"}}>
                        {columnName.replace(/_/g, " ")
                          .replace('Debt Equity','Debt/Equity')
                          .replace('P BV','P/BV')
                          .replace('Percentage','(%)')
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">{dataValue}</StyledTableCell>
                    </StyledTableRow>
                  );
                } else {
                  return null; // Don't render the row if dataValue is empty
                }
              })}
            </TableBody>
          </Table>
          
        </TableContainer>
      {/* </div> */}
      {/* <div style={{flexBasis: "100%", maxWidth:"100%"}}> */}
      {ipoDetails.pre_post_ipo && ipoDetails.pre_post_ipo.length > 0 ? (
        <TableContainer component={Paper} style={{marginBottom:"20px",maxWidth:"600px"}}>
          <Table sx={{ minWidth: 350 }} aria-label="customized table">
            <caption>
              <div>
                <h4>Note:-</h4>
                <p>EPS = Profit After Tax/No. of outstanding shares</p>
                <p>P/E = Issue Price/EPS</p>
              </div>
            </caption>
            <TableHead>
              <TableRow>
                {pre_post_ipo_columns.map((columnName, index) => (
                  <StyledTableCell key={index} align={index === 0 ? "left" : "left"}>
                    {columnName.replace(/_/g, " ").replace('EPS Rs','EPS (Rs)').replace('PE x','P/E (x)')}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pre_post_ipo_rowData.map((row, index) => (
                <StyledTableRow key={index}>
                  {row
                    .filter((i) => i != "")
                    .map((cell, cellIndex) => (
                      <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                        {cell}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
              ))}
                {/* <StyledTableRow > <div>
                <h4>Note:-</h4>
                <p>EPS = Profit After Tax/No. of outstanding shares</p>
                <p>P/E = Issue Price/EPS</p>
              </div> </StyledTableRow> */}
            </TableBody>
          </Table>
        </TableContainer>): null}
      {/* </div> */}
    {/* </div> */}
  </>
): null}

{ipoDetails?.ipo_static_details && ipoDetails?.ipo_static_details?.length > 0 && (<div style={{display:"flex",flexDirection:"column",gap:"20px"}}>

{ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[3]?.length > 0 ? (<div style={{backgroundColor:"#f9f9f9",borderRadius:"5px",border:"1px solid #ccc"}}> <h4 style={{color:"#666",margin:"0px 0px",padding:"10px 10px"}}>{ipoDetails?.IssuerCompany} Objectives</h4>
  <HtmlContent htmlContent={ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[3]} /></div>):null}
  

  {ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[4]?.length > 0 ?(<div style={{backgroundColor:"#f9f9f9",borderRadius:"5px",border:"1px solid #ccc"}}> <h4 style={{color:"#666",margin:"0px 0px",padding:"10px 10px"}}>{ipoDetails?.IssuerCompany} Prospectus</h4>
  {/* <div>{ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[4]}</div> */}
  <div style={{fontSize:"14px",listStyleType:"'⮚ '",borderTop:"1px solid #ccc",padding:"10px 10px"}}>
  {ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[4] && 
    Object.entries(JSON.parse(ipoDetails.ipo_static_details[0].data[4])).map(([key, value]) => (
      <li key={key} style={{padding:"5px 5px"}}>
        <a href={value} target="_blank" rel="noopener noreferrer" onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = 'black'} style={{color:"black"}}>
          {key}
        </a>
      </li>
    ))
  }
</div>
</div>):null}
 
{ipoDetails && ipoDetails.ipo_static_details && ipoDetails.ipo_static_details?.length > 0 && ipoDetails.ipo_static_details[0].data[5] && ipoDetails.ipo_static_details[0].data[5]?.length > 0 ?(<div style={{backgroundColor:"#f9f9f9",borderRadius:"5px",border:"1px solid #ccc"}}>

<h4 style={{color:"#666",margin:"0px 0px",padding:"10px 10px"}}>Company Contact Details</h4>
{/* {ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[5] && Object.keys(JSON.parse(ipoDetails.ipo_static_details[0].data[5].Address))} */}
{/* <div style={{ fontSize: "15px" }}> */}
    {/* Check if ipoDetails and ipo_static_details are available */}
    {ipoDetails && ipoDetails.ipo_static_details && ipoDetails.ipo_static_details?.length > 0 ? (
      (() => {
        // Extract and parse data
        const ipoStaticDetailRaw = ipoDetails.ipo_static_details[0].data[5];
        let ipoStaticDetail = null;

        try {
          // Attempt to parse JSON if it's a string
          ipoStaticDetail = typeof ipoStaticDetailRaw === 'string'
            ? JSON.parse(ipoStaticDetailRaw)
            : ipoStaticDetailRaw;
        } catch (e) {
          console.error('Error parsing JSON:', e);
          return <div>Error parsing data</div>;
        }

        // Check if ipoStaticDetail is a valid object
        if (ipoStaticDetail && typeof ipoStaticDetail === 'object') {
          return (
            <div style={{ fontSize: "14px",borderTop:"1px solid #ccc",padding:"10px 10px" }}>
              {/* <h3 style={{ margin: "0px 5px 5px 0px" }}>{ipoStaticDetail.Company}</h3> */}
              {/* <div>{ipoStaticDetail.Company}</div> */}
              <div style={{ margin: "0px 5px 15px 0px",fontSize: "16px"}}>
              <div style={{width:"100%",fontSize: "15px",margin:"0px 0px 5px 0px",fontWeight:"bold"}}>{ipoStaticDetail.Company}</div>
              <div>{ipoStaticDetail.Address}</div>
              </div>
              <div style={{ margin: "0px 5px 5px 0px", display: "flex", alignItems: "center" }}>
                <strong style={{maxWidth:"80px",width:"100%" }}>Phone:</strong>
                <div>{ipoStaticDetail.Phone}</div>
              </div>
               {/* <div style={{ display: "flex",alignItems: "center", flexWrap: "wrap", margin: "0px 5px 5px 0px" }}>
                <div style={{display: "flex", alignItems: "center" }}>
                  <strong style={{ flexShrink: 0 }}>Phone:</strong>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "5px" }}>{ipoStaticDetail.Phone}</div>
                </div>
              </div> */}
              
              <div style={{ margin: "0px 5px 5px 0px", display: "flex", alignItems: "center" }}>
                <strong style={{maxWidth:"80px",width:"100%" }}>Email:</strong>
                <a
                  href={`mailto:${ipoStaticDetail.Email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                  onMouseLeave={(e) => e.target.style.color = '#007BFF'}
                  style={{ color: "#007BFF",display: "block", 
                  wordBreak: "break-word", 
                  overflowWrap: "break-word"  }}
                >
                  {ipoStaticDetail.Email}
                </a>
              </div>
              
              <div style={{ margin: "0px 5px 0px 0px", display: "flex", alignItems: "center" }}>
                <strong style={{maxWidth:"80px",width:"100%" }}>Website:</strong>
                <a
                  href={ipoStaticDetail.Website}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                  onMouseLeave={(e) => e.target.style.color = '#007BFF'}
                  style={{ color: "#007BFF",display: "block", 
                  wordBreak: "break-word", 
                  overflowWrap: "break-word"  }}
                >
                  {ipoStaticDetail.Website}
                </a>
              </div>
              </div>
          );
        } else {
          return <div>Invalid data format</div>;
        }
      })()
    ) : null}
</div>):null}

  {/* </div> */}
  {/* <div style={{fontSize:"15px"}}>{ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[5] && 
    Object.entries(JSON.parse(ipoDetails.ipo_static_details[0].data[5])).map(([key, value]) => (
      <div key={key} style={{padding:"5px"}}>
      
          <strong>{key}:</strong><div>{value}</div>
       
      </div>
    ))}</div> */}
  {/* {ipoDetails.ipo_static_details && ipoDetails.ipo_static_details[0].data[3]} */}
</div>)}
 {/* {ipoDetails.ipo_key_performance_indicator && ipoDetails.ipo_key_performance_indicator.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Key Performance Indicator</h4>
        <div style={{display:"flex",flexWrap:"wrap",alignContent:"center",justifyContent:"space-between",marginBottom:"15px"}}>
        <TableContainer component={Paper} style={{maxWidth:"350px"}}>
  <Table sx={{ minWidth: 350 }} aria-label="customized table">
    <TableHead>
      <TableRow>
        <StyledTableCell align="left">KPI</StyledTableCell>
        <StyledTableCell align="left">Values</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {ipoDetails.ipo_key_performance_indicator[0].columns.slice(2).map((columnName, index) => {
        const dataValue = ipoDetails.ipo_key_performance_indicator[0].data.slice(2)[index];
        if (dataValue !== "") {
          return (
            <StyledTableRow key={index}>
              <StyledTableCell align="left" style={{fontWeight:"bold",color:"#666"}}>
                {columnName.replace(/_/g, " ")
                  .replace('Debt Equity','Debt/Equity')
                  .replace('P BV','P/BV')
                  .replace('Percentage','(%)')
                }
              </StyledTableCell>
              <StyledTableCell align="left" >
                {dataValue}
              </StyledTableCell>
            </StyledTableRow>
          );
        } else {
          return null; // Don't render the row if dataValue is empty
        }
      })}
    </TableBody>
  </Table>
</TableContainer>

        
      
    {ipoDetails.pre_post_ipo && ipoDetails.pre_post_ipo.length > 0 ? (
    <TableContainer component={Paper} style={{maxWidth:"400px",marginTop:"25px"}}>
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      <caption><div><h4>Note:-</h4><p>EPS = Profit After Tax/No. of outstanding shares</p><p>P/E = Issue Price/EPS</p></div></caption>
           <TableHead>
         <TableRow>
         {pre_post_ipo_columns.map((columnName, index) => (
                     <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace('EPS Rs','EPS (Rs)').replace('PE x','P/E (x)')}
                     </StyledTableCell>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {pre_post_ipo_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer> ): null}
    </div>
         </>
    ): null} */}

      </div>

      {blogs && blogs.length > 0 ? (
      <div ref={tab2Ref} style={{padding:"15px 15px",marginTop:"5px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto"
      // ,bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" 
      }}>
        <h2 style={{borderBottom: "2px solid #dddcdd",textAlign:"center",paddingBottom:"10px",margin:"15px 0px 20px 0px"}}>IPO News</h2>
        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"20px",justifyContent:"center"}}>
        {blogs && blogs.map((blog,index)=>(blog.data[13] === 0 ? ( 
        <Link 
        // to={`/news/ipo/${encodeURIComponent(blog.data[2].toLowerCase().replace(/ /g, '-'))}`}
        to={`/news/ipo/${encodeURIComponent(
          blog.data[2].toLowerCase()
            .replace(/[^a-z0-9\s-]/g, '') // Remove unwanted symbols
            .replace(/\s+/g, '-') // Replace spaces with dashes
        )}`}
        style={{cursor: 'pointer',textDecoration:"none"}} key={index}>
        <div  style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px" ,  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
    {/* // onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    // onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}> */}
        {/* ,boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)" */}
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image={blog.data[3]}
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h8" component="div" style={{color:"#333",fontWeight:"bold",wordWrap:"break-word"}}>
          {blog.data[2].length > 60 ? blog.data[2].substring(0, 60) + "..." : blog.data[2]}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666",wordWrap:"break-word",fontSize:"11px"}}>
          
            {blog.data[4].length > 100 ? blog.data[4].substring(0, 100) + "..." : blog.data[4]}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"12px",color:"black"}}>
          Published by: {blog.data[10]} | {formatDate(blog.data[8])}
          </Typography>
        </div>
      </div>
    </div>
    </Link>):( <Link to={blog.data[15]} style={{cursor: 'pointer',textDecoration:"none"}} target="_blank" key={index}>
        <div  style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px" ,  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
    {/* // onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    // onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}> */}
        {/* ,boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)" */}
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image={blog.data[3]}
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h8" component="div" style={{color:"#333",fontWeight:"bold",wordWrap:"break-word"}}>
          {blog.data[2].length > 60 ? blog.data[2].substring(0, 60) + "..." : blog.data[2]}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666",wordWrap:"break-word",fontSize:"11px"}}>
          
            {blog.data[4].length > 100 ? blog.data[4].substring(0, 100) + "..." : blog.data[4]}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"12px",color:"black"}}>
          Published by: {blog.data[10]} | {formatDate(blog.data[8])}
          </Typography>
        </div>
      </div>
    </div>
    </Link>)))}

    {/* <Link to={`/news/ipo/${"LIZARD"}`} style={{cursor: 'pointer',textDecoration:"none"}}>
        <div style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px",  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
        
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSQvmTzssuDbtILVom-5mit_hLJ0tXcvjei5PUVpCyQxlaSupeewzzSry8hjN7WAVqBgg&usqp=CAU"
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h6" component="div" style={{color:"#333",fontWeight:"bold"}}>
            Lizard species, ranging
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666"}}>
          
            {text.length > 120 ? text.substring(0, 120) + "..." : text}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"13px",color:"black"}}>
          Published by: Anjali | Date
          </Typography>
        </div>
      </div>
    </div>
    </Link>

    <Link to={`/news/ipo/${"LIZARD"}`} style={{cursor: 'pointer',textDecoration:"none"}}>
        <div style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px",  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
       
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm4nR_-Z6M8hnyZ-cdDw6Ufas-pZdVWXG8FYdmrKtLUy5dFiDvcciCy7-Xmehm746F7l4&usqp=CAU"
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h6" component="div" style={{color:"#333",fontWeight:"bold"}}>
            Lizard species, ranging
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666"}}>
          
            {text.length > 120 ? text.substring(0, 120) + "..." : text}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"13px",color:"black"}}>
          Published by: Anjali | Date
          </Typography>
        </div>
      </div>
    </div>
    </Link> */}

    {/* <Link to={`/news/ipo/${"LIZARD"}`} style={{cursor: 'pointer',textDecoration:"none"}}>
        <div style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px",  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
        
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWxkM3dW0RJ7ThTmzmH8vc1bJc-dbFwsC9Xoy1Xa51mg&s"
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h6" component="div" style={{color:"#333",fontWeight:"bold"}}>
            Lizard species, ranging
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666"}}>
          
            {text.length > 120 ? text.substring(0, 120) + "..." : text}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"13px",color:"black"}}>
          Published by: Anjali | Date
          </Typography>
        </div>
      </div>
    </div>
    </Link> */}

    {/* <Card sx={{ maxWidth: 340 }} style={{zIndex:"-3"}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSQvmTzssuDbtILVom-5mit_hLJ0tXcvjei5PUVpCyQxlaSupeewzzSry8hjN7WAVqBgg&usqp=CAU"
          alt="green iguana"
          // style={{borderRadius:"10px"}}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" style={{color:"#333",fontWeight:"bold"}}>
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666"}}>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"13px",color:"black"}}>
          Published by: Anjali | Date
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card sx={{ maxWidth: 340 }} style={{zIndex:"-3"}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm4nR_-Z6M8hnyZ-cdDw6Ufas-pZdVWXG8FYdmrKtLUy5dFiDvcciCy7-Xmehm746F7l4&usqp=CAU"
          alt="green iguana"
          // style={{borderRadius:"10px"}}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" style={{color:"#333",fontWeight:"bold"}}>
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666"}}>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"13px",color:"black"}}>
          Published by: Anjali | Date
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card> */}
        </div>

        
        <Link to={`/ipo-news`} style={{cursor: 'pointer',textDecoration:"none"}}>
        <div style={{color:"#666",marginTop:"20px",fontWeight:"bold",fontSize:"15px",textAlign:"center"}}>
       
            <label onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            onMouseLeave={(e) => e.target.style.color = '#666'}  onClick={handleShowLess}>-- Show More News --</label>
         
        </div></Link>         
       

      
        {/* {ipoDetails.ipo_news && ipoDetails.ipo_news.length > 0 ? (
        <p>This is the content of Tab 2</p>
      ): null} */}
      </div>):null}

      <div ref={tab3Ref} style={{padding:"15px 15px",marginTop:"30px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0"}}>Subscription Status</h2>
        {/* <hr/> */}

        {ipoDetails.ipo_subscription_status && ipoDetails.ipo_subscription_status.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Subscription Status Live</h4> 
        <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_subscription_status_columns.map((columnName, index) => (
                     <StyledTableCell1 key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace('NonInstitutional Buyers','Non-Institutional Buyers').replace(' star','*').replace('bNII bids above 10L','bNII (bids above ₹10L)').replace('sNII bids below 10L','sNII (bids below ₹10L)')}
                     </StyledTableCell1>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_subscription_status_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell.replace("[.]","-")}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
        </>
    ): <p style={{color:"red",marginBottom:"0"}}>Sorry, we couldn't find the subscription details.</p>}
    
      {ipoDetails.ipo_total_application && ipoDetails.ipo_total_application.length > 0 ? (
      <div style={{display:"flex",alignItems:"center"}}>
        <h4 style={{color:"#666",marginRight:"5px"}}>Total Application:</h4><label>{ipoDetails.ipo_total_application[0].data[2]}</label>
         </div>
    ): null}

    {ipoDetails.ipo_subscription_status_times && ipoDetails.ipo_subscription_status_times.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Subscription Details (Day-wise)</h4> 
        <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_subscription_status_times_columns.map((columnName, index) => (
                     <StyledTableCell1 key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace(' star','*').replace('bNII bids above 10L','bNII (bids above ₹10L)').replace('sNII bids below 10L','sNII (bids below ₹10L)')}
                     </StyledTableCell1>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_subscription_status_times_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
        
        </>
    ): null}

        {/* <br/> */}
        <div style={{padding:"20px 0 5px 0",fontSize:"15px"}}>
        <a href="https://www.ipotank.com/ipo-investor-categories" style={{color:"#007BFF",padding:"20px 0 0 0",fontSize:"15px"}} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                  onMouseLeave={(e) => e.target.style.color = '#007BFF'} >
        Know more about IPO Investor Categories
      </a></div>
        {/* <p>This is the content of Tab 3</p> */}
        {/* <h3 style={{ color:"#666",marginTop:"45px" }}>IPO Investor Categories</h3>
        <ul style={{fontSize:"15px"}}>
            <li>
                <strong>Qualified Institutional Buyers (QIB)</strong>
                <p>Financial Institutions, Banks, FIIs, and Mutual Funds registered with SEBI are called QIBs. In most cases, QIBs represent small investors who invest through mutual funds, ULIP schemes of insurance companies, and pension schemes.</p>
            </li>
            <li>
                <strong>Non-Institutional Investors(NII)</strong>
                <p>Retail Individual Investors (HNI), NRIs, Companies, Trusts, etc who bid for shares worth more than Rs 2 lakhs are known as Non-institutional bidders (NII). Unlike QIB bidders, they do not need SEBI registration.</p>
                <p>NII category has two subcategories:</p>
                <ol>
                    <li>
                        <strong> sNII (bids below Rs 10L)</strong>
                        <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p>
                    </li>
                    <li>
                        <strong> bNII (bids above Rs 10L)</strong>
                        <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p>
                    </li>
                </ol>
            </li>
            <li>
                <strong>Retail Individual Investors(RII)</strong>
                <p>The retail individual investor or NRIs who apply up to Rs 2 lakhs in an IPO are considered as RII reserved category.</p>
            </li>
            <li>
                <strong>Employee (EMP)</strong>
                <p>A category of eligible employees who have a reserved quota in the IPO.</p>
            </li>
            <li>
                <strong>Others</strong>
                <p>A category of eligible shareholders or other investors who have a reserved quota in the IPO.</p>
            </li>
        </ul>
        <h3 itemprop="name" style={{color:"#666",marginTop:"45px"}}>What is the difference between RII, NII, QIB and Anchor Investor?</h3>
        <div itemprop="text" style={{fontSize:"15px"}}>
            <p>Investors can apply for shares in one of the following categories:</p>
            <ol>
                <li>
                <strong>Retail Individual Investor (RII)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Resident Indian Individuals, NRIs and HUFs who apply for less than Rs 2 lakhs in an IPO under RII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 35% of the Offer is reserved for RII category.</li>
                        <li style={{margin:"10px 0px"}}>NRI or HUF who applying in an IPO with less than Rs 2,00,000 can apply in RII category.</li>
                        <li style={{margin:"10px 0px"}}>RII category allows bid at cut-off price.</li>
                        <li><strong>Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in RII Category, full allotment to all applicants.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in this category - The allotment is taken place via lottery if the retail portion is oversubscribed, and applicants will receive at least one lot regardless of how many lots they have applied for.
                                    <p>For example, if the IPO subscribed 2 times (application-wise) in the retail category, 1 out of 2 applicants will get 1 lot irrespective of how many shares they applied for.</p>
                                    <p>If investor A applied for Rs 2 lakhs (15 lots), investor B applied for 1 lot and investor C applied for Rs 1 lakh (7 lots) in an IPO at a cut-off price. If IPO is subscribed 3 times (application-wise) in the Retail Category, the allotment will be done through the lottery and only 1 of 3 applicants will get 1 lot allocated. It doesn't matter how many shares they have applied.</p>
                                </li>
                            </ul>
                        </li>
                        <li style={{margin:"10px 0px"}}>Retail and non-institutional bidders are permitted to withdraw their bids until the day of allotment.</li>
                        <li style={{margin:"10px 0px"}}>Note:
                            <ul>
                                <li style={{margin:"10px 0px"}}>Always apply at cut-off price in this category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO oversubscribe, apply only 1 lot per IPO application.</li>
                                <li style={{margin:"10px 0px"}}>To maximize the allotment, apply though multiple accounts on your family members name.</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Non-institutional bidders (NII)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Resident Indian individuals, NRIs, HUFs, Companies, Corporate Bodies, Scientific Institutions, Societies, and Trusts who apply for more than Rs 2 lakhs of IPO shares fall under the NII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 15% of the Offer is reserved for the NII category.</li>
                        <li style={{margin:"10px 0px"}}><strong>A high Net-worth Individual (HNI)</strong> who applies for over Rs 2 Lakhs in an IPO falls under this category.</li>
                        <li style={{margin:"10px 0px"}}>Non-institutional bidders are not permitted to withdraw their bids once placed. They could modify the bid price.</li>
                        <li style={{margin:"10px 0px"}}>NIIs are not eligible to bid at the cut-off price.</li>
                        <li style={{margin:"10px 0px"}}>NII need not register with SEBI.</li>
                        <li>
                            <strong>NII Sub-categories</strong>
                            <p>NII category has two subcategories:</p>
                            <ol>
                                <li><strong>sNII or sHNI (bids below Rs 10L)</strong>
                                    <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p></li>
                                <li><strong>bNII or bHNI (bids above Rs 10L)</strong>
                                    <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p></li>
                            </ol>
                        </li>
                        <li><strong>sNII or sHNI Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the sNII sub-category, full allotment to all applicants in the sNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the sNII sub-category, the allotment to each investor shall<strong> not be less than the minimum application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the sNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the sNII investor gets shares worth of minimum application size which is just above Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Example: if the IPO subscribed 2 times (application-wise) in the sNII sub-category, 1 out of 2 applicants will get the minimum sHNI application (worth around Rs 2 Lakhs) irrespective of how many shares they applied for.</li>
                            </ul>
                        </li>
                        <li><strong>bNII or bHNI Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the bNII sub-category, full allotment to all applicants in the bNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the bNII sub-category, the allotment to each investor shall<strong> not be less than the minimum sNII application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the bNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the bNII investor gets shares worth of minimum application size which is just around Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Even though the investment limit for Big HNI is more than or equal to Rs 10 lakhs, the investors will receive shares worth Rs 2 lakhs on a lottery basis (same as for Small HNI).</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Qualified Institutional Bidders (QIB's)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Public financial institutions, commercial banks, mutual funds and Foreign Portfolio Investors etc can apply in QIB category. SEBI registration is required for institutions to apply under this category.</li>
                        <li style={{margin:"10px 0px"}}>50% of the Offer Size is reserved for QIB's</li>
                        <li style={{margin:"10px 0px"}}>Allotment Basis - Proportionate.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are mostly representatives of small investors who invest through mutual funds, ULIP schemes of insurance companies and pension schemes.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are prohibited by SEBI guidelines to withdraw their bids after the close of the IPOs.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li><br/>
                <li>
                <strong>Anchor Investor</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>An anchor investor in a public issue refers to a qualified institutional buyer (QIB) making an application for a value of Rs 10 crores or more through the book-building process. An anchor investor can attract investors to public offers before they hit the market to boost their confidence.</li>
                        <li style={{margin:"10px 0px"}}>Up to 60% of the QIB Category can be allocated to Anchor Investors;</li>
                        <li style={{margin:"10px 0px"}}>Anchor Investor Offer Price is decided separately.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's has different Anchor Investor Bid/Offer Period.</li>
                        <li style={{margin:"10px 0px"}}>The minimum application size for each anchor investor should be Rs 10 crores. No merchant banker, promoter or their relatives can apply for shares under the anchor investor category. In offers of size less than Rs 250 crores, there can be a maximum of 15 anchor investors, but in those over Rs 250 crores, SEBI recently removed the cap on the number of anchor investors.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li>
            </ol>
        </div> */}


{/* <p><em>Disclaimer: The above information / data is sourced from National Stock Exchange (NSE) and Bombay Stock Exchange (BSE) websites and is subject to change in real time. For latest information / data, please refer to the respective websites. The information / data provided here in is for informational purposes only and is provided by Chittorgarh.com on "AS IS" and "AS AVAILABLE" basis and without warranty, express or implied. Chittorgarh.com does not warrant the accuracy, adequacy or completeness of the information you obtain through the above websites. Chittorgarh.com shall not be liable in any way for any discrepancies, errors, omissions, losses or damage. Chittorgarh.com, including its affiliates and its directors, staff and employees, shall not be liable for any loss, damage of any kind, including but not limited to direct, indirect, punitive, exemplary, consequential damages and lost profits arising in any way out of the use of information / data obtained through the said websites. The recipient alone is fully responsible / liable for any decision made based on this information / data. All recipients, before relying on the said information, should do their own research and seek appropriate professional advice.</em></p> */}
   
      </div>


      {ipoDetails.ipo_details && ipoDetails.ipo_details[0]?.data[7] && (pricePerShareRange2 || pricePerShareRange1) ? (
      <div ref={tab5Ref} style={{padding:"15px 15px",marginTop:"30px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0"}}>Calculator</h2>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Shares to Apply By Amount Calculator</h4>
<p style={{fontSize:"15px"}}>Enter the amount you would like to invest in {ipoDetails?.IssuerCompany} and find how many shares you should apply. In an IPO, shares are applied in lots. This calculator helps you to find an exact number of shares to apply for the given amount.</p>
        {/* <h4 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"20px"}}>investment : ₹{lastValue} </h4> */}
        {/* <p ><strong>Lot Size (Shares) :</strong> {ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7].replace(' Shares','').toLocaleString('en-IN')}</p>
        <p ><strong>Shares Issue Price (Rs) :</strong> {pricePerShareRange2 || pricePerShareRange1}</p>
        <p ><strong>Lot Price (Rs):</strong> {lastValue.toLocaleString('en-IN')} </p>
            
      <label style={{marginRight:"10px"}}>Amount to Invest (Rs):</label>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="0"
        style={{marginRight:"10px"}}
      />

    
      <button onClick={handleSubmit}>Find No. of Shares to Apply</button>


      <p ><strong>Lots to Apply :</strong> {LotApply || 0}</p>
      <p ><strong>Shares to Apply :</strong> {ShareApply || 0}</p>
      <p ><strong>Total Investment (Rs):</strong> {TotalAmount || 0} </p> */}


      
    <table style={{ 
      width: "100%", 
      maxWidth:"600px",
      minWidth:"auto",
      // borderCollapse: "collapse", 
      // borderRadius:"20px",
      padding: "10px 10px",
      border:"1px solid #e6eefb",
      // borderRadius:"15px",
      backgroundColor:"#e6eefb",
      fontFamily: "Arial, sans-serif", 
      fontSize: "15px" ,
      alignContent:"center",
      // textAlign:"center"
      // justifyContent:"center",
    }}>
      <tbody>
      <div style={{display:"flex",flexDirection:"column",flexWrap:"nowrap",gap:"10px",justifyContent:"center",alignContent:"center"}}>
        <tr>
          <td style={{ 
            padding: "10px",
            // backgroundColor: "#f2f2f2",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // border:"1px solid whitesmoke",
      // borderRadius:"15px",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lot Size (Shares)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
      // borderRadius:"15px",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {ipoDetails.ipo_details && ipoDetails.ipo_details[0].data[7].replace(' Shares','').toLocaleString('en-IN')}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Shares Issue Price (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            // borderRadius:"15px",
            backgroundColor: "white",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {pricePerShareRange2 || pricePerShareRange1}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lot Price (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            // borderRadius:"15px",
            backgroundColor: "white",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {lastValue.toLocaleString('en-IN')}
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={{ 
            padding: "15px",
            backgroundColor: "white",
            fontWeight: "bold",
            // border: "1px solid #dddddd",
            textAlign: "center",
            width: "100%",
            // gap:"15px"
          }}>
            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"15px",justifyContent:"center",alignContent:"center",width: "100%", 
      maxWidth:"700px"}}>
            <label style={{color:"#666",padding: "8px 0"}}>Amount to Invest (Rs):</label>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="0"
              style={{ padding: "8px 16px", borderRadius: "5px", border: "1px solid #ccc",maxWidth:"180px",width:"100%",alignContent:"center",fontSize:"15px",backgroundColor:"#f1efef" }}
            />
            <button style={{ 
              padding: "8px 16px",
              background: "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}
            onClick={handleSubmit} >Find No. of Shares to Apply</button></div>
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lots to Apply
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {LotApply || 0}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Shares to Apply
          </td>
          <td style={{ 
            padding: "10px",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            border: "1px solid #dddddd",
            alignContent:"center",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {ShareApply || 0}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Total Investment (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            alignContent:"center",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {TotalAmount || 0}
          </td>
        </tr>
        </div>
      </tbody>
    </table>

        {/* {ipoDetails.ipo_subscription_status && ipoDetails.ipo_subscription_status.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Subscription Status Live</h4> 
        <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_subscription_status_columns.map((columnName, index) => (
                     <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace('NonInstitutional Buyers','Non-Institutional Buyers').replace(' star','*').replace('bNII bids above 10L','bNII (bids above ₹10L)').replace('sNII bids below 10L','sNII (bids below ₹10L)')}
                     </StyledTableCell>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_subscription_status_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
        </>
    ): <p style={{color:"red"}}>Sorry, we couldn't find the subscription details.</p>}
    
      {ipoDetails.ipo_total_application && ipoDetails.ipo_total_application.length > 0 ? (
      <div style={{display:"flex",alignItems:"center"}}>
        <h4 style={{color:"#666",marginRight:"5px"}}>Total Application:</h4><label>{ipoDetails.ipo_total_application[0].data[2]}</label>
         </div>
    ): null}

    {ipoDetails.ipo_subscription_status_times && ipoDetails.ipo_subscription_status_times.length > 0 ? (
      <>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Subscription Details (Day-wise)</h4> 
        <TableContainer component={Paper} >
       <Table sx={{ minWidth: 350 }} aria-label="customized table">
      
           <TableHead>
         <TableRow>
         {ipo_subscription_status_times_columns.map((columnName, index) => (
                     <StyledTableCell key={index}  align={index === 0 ? "left" : "left"}>
                       {columnName.replace(/_/g, " ").replace(' star','*').replace('bNII bids above 10L','bNII (bids above ₹10L)').replace('sNII bids below 10L','sNII (bids below ₹10L)')}
                     </StyledTableCell>
                   ))}
             </TableRow>
         </TableHead>
         <TableBody>
         {ipo_subscription_status_times_rowData.map((row, index) => (
                   <StyledTableRow key={index}>
                     {row
                       .filter((i) => i != "")
                       .map((cell, cellIndex) => (
                         <StyledTableCell key={cellIndex} align={cellIndex === 0 ? "left" : "left"} style={{fontWeight:cellIndex === 0 ? "bold" : "" ,color:cellIndex === 0 ? "#666" : ""}}>
                           {cell}
                         </StyledTableCell>
                       ))}
                   </StyledTableRow>
                 ))}
         </TableBody>
       </Table>
     </TableContainer>
        
        </>
    ): null} */}

    
        {/* <h3 style={{ color:"#666",marginTop:"45px" }}>IPO Investor Categories</h3>
        <ul>
            <li>
                <strong>Qualified Institutional Buyers (QIB)</strong>
                <p>Financial Institutions, Banks, FIIs, and Mutual Funds registered with SEBI are called QIBs. In most cases, QIBs represent small investors who invest through mutual funds, ULIP schemes of insurance companies, and pension schemes.</p>
            </li>
            <li>
                <strong>Non-Institutional Investors(NII)</strong>
                <p>Retail Individual Investors (HNI), NRIs, Companies, Trusts, etc who bid for shares worth more than Rs 2 lakhs are known as Non-institutional bidders (NII). Unlike QIB bidders, they do not need SEBI registration.</p>
                <p>NII category has two subcategories:</p>
                <ol>
                    <li>
                        <strong> sNII (bids below Rs 10L)</strong>
                        <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p>
                    </li>
                    <li>
                        <strong> bNII (bids above Rs 10L)</strong>
                        <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p>
                    </li>
                </ol>
            </li>
            <li>
                <strong>Retail Individual Investors(RII)</strong>
                <p>The retail individual investor or NRIs who apply up to Rs 2 lakhs in an IPO are considered as RII reserved category.</p>
            </li>
            <li>
                <strong>Employee (EMP)</strong>
                <p>A category of eligible employees who have a reserved quota in the IPO.</p>
            </li>
            <li>
                <strong>Others</strong>
                <p>A category of eligible shareholders or other investors who have a reserved quota in the IPO.</p>
            </li>
        </ul>
        <h3 itemprop="name" style={{color:"#666",marginTop:"45px"}}>What is the difference between RII, NII, QIB and Anchor Investor?</h3>
        <div itemprop="text">
            <p>Investors can apply for shares in one of the following categories:</p>
            <ol>
                <li>
                <strong>Retail Individual Investor (RII)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Resident Indian Individuals, NRIs and HUFs who apply for less than Rs 2 lakhs in an IPO under RII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 35% of the Offer is reserved for RII category.</li>
                        <li style={{margin:"10px 0px"}}>NRI or HUF who applying in an IPO with less than Rs 2,00,000 can apply in RII category.</li>
                        <li style={{margin:"10px 0px"}}>RII category allows bid at cut-off price.</li>
                        <li><strong>Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in RII Category, full allotment to all applicants.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in this category - The allotment is taken place via lottery if the retail portion is oversubscribed, and applicants will receive at least one lot regardless of how many lots they have applied for.
                                    <p>For example, if the IPO subscribed 2 times (application-wise) in the retail category, 1 out of 2 applicants will get 1 lot irrespective of how many shares they applied for.</p>
                                    <p>If investor A applied for Rs 2 lakhs (15 lots), investor B applied for 1 lot and investor C applied for Rs 1 lakh (7 lots) in an IPO at a cut-off price. If IPO is subscribed 3 times (application-wise) in the Retail Category, the allotment will be done through the lottery and only 1 of 3 applicants will get 1 lot allocated. It doesn't matter how many shares they have applied.</p>
                                </li>
                            </ul>
                        </li>
                        <li style={{margin:"10px 0px"}}>Retail and non-institutional bidders are permitted to withdraw their bids until the day of allotment.</li>
                        <li style={{margin:"10px 0px"}}>Note:
                            <ul>
                                <li style={{margin:"10px 0px"}}>Always apply at cut-off price in this category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO oversubscribe, apply only 1 lot per IPO application.</li>
                                <li style={{margin:"10px 0px"}}>To maximize the allotment, apply though multiple accounts on your family members name.</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Non-institutional bidders (NII)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Resident Indian individuals, NRIs, HUFs, Companies, Corporate Bodies, Scientific Institutions, Societies, and Trusts who apply for more than Rs 2 lakhs of IPO shares fall under the NII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 15% of the Offer is reserved for the NII category.</li>
                        <li style={{margin:"10px 0px"}}><strong>A high Net-worth Individual (HNI)</strong> who applies for over Rs 2 Lakhs in an IPO falls under this category.</li>
                        <li style={{margin:"10px 0px"}}>Non-institutional bidders are not permitted to withdraw their bids once placed. They could modify the bid price.</li>
                        <li style={{margin:"10px 0px"}}>NIIs are not eligible to bid at the cut-off price.</li>
                        <li style={{margin:"10px 0px"}}>NII need not register with SEBI.</li>
                        <li>
                            <strong>NII Sub-categories</strong>
                            <p>NII category has two subcategories:</p>
                            <ol>
                                <li><strong>sNII or sHNI (bids below Rs 10L)</strong>
                                    <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p></li>
                                <li><strong>bNII or bHNI (bids above Rs 10L)</strong>
                                    <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p></li>
                            </ol>
                        </li>
                        <li><strong>sNII or sHNI Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the sNII sub-category, full allotment to all applicants in the sNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the sNII sub-category, the allotment to each investor shall<strong> not be less than the minimum application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the sNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the sNII investor gets shares worth of minimum application size which is just above Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Example: if the IPO subscribed 2 times (application-wise) in the sNII sub-category, 1 out of 2 applicants will get the minimum sHNI application (worth around Rs 2 Lakhs) irrespective of how many shares they applied for.</li>
                            </ul>
                        </li>
                        <li><strong>bNII or bHNI Allotment Basis</strong>
                            <ul>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the bNII sub-category, full allotment to all applicants in the bNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the bNII sub-category, the allotment to each investor shall<strong> not be less than the minimum sNII application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the bNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the bNII investor gets shares worth of minimum application size which is just around Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Even though the investment limit for Big HNI is more than or equal to Rs 10 lakhs, the investors will receive shares worth Rs 2 lakhs on a lottery basis (same as for Small HNI).</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Qualified Institutional Bidders (QIB's)</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>Public financial institutions, commercial banks, mutual funds and Foreign Portfolio Investors etc can apply in QIB category. SEBI registration is required for institutions to apply under this category.</li>
                        <li style={{margin:"10px 0px"}}>50% of the Offer Size is reserved for QIB's</li>
                        <li style={{margin:"10px 0px"}}>Allotment Basis - Proportionate.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are mostly representatives of small investors who invest through mutual funds, ULIP schemes of insurance companies and pension schemes.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are prohibited by SEBI guidelines to withdraw their bids after the close of the IPOs.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li><br/>
                <li>
                <strong>Anchor Investor</strong>
                    <ul>
                        <li style={{margin:"10px 0px"}}>An anchor investor in a public issue refers to a qualified institutional buyer (QIB) making an application for a value of Rs 10 crores or more through the book-building process. An anchor investor can attract investors to public offers before they hit the market to boost their confidence.</li>
                        <li style={{margin:"10px 0px"}}>Up to 60% of the QIB Category can be allocated to Anchor Investors;</li>
                        <li style={{margin:"10px 0px"}}>Anchor Investor Offer Price is decided separately.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's has different Anchor Investor Bid/Offer Period.</li>
                        <li style={{margin:"10px 0px"}}>The minimum application size for each anchor investor should be Rs 10 crores. No merchant banker, promoter or their relatives can apply for shares under the anchor investor category. In offers of size less than Rs 250 crores, there can be a maximum of 15 anchor investors, but in those over Rs 250 crores, SEBI recently removed the cap on the number of anchor investors.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li>
            </ol>
        </div> */}


{/* <p><em>Disclaimer: The above information / data is sourced from National Stock Exchange (NSE) and Bombay Stock Exchange (BSE) websites and is subject to change in real time. For latest information / data, please refer to the respective websites. The information / data provided here in is for informational purposes only and is provided by Chittorgarh.com on "AS IS" and "AS AVAILABLE" basis and without warranty, express or implied. Chittorgarh.com does not warrant the accuracy, adequacy or completeness of the information you obtain through the above websites. Chittorgarh.com shall not be liable in any way for any discrepancies, errors, omissions, losses or damage. Chittorgarh.com, including its affiliates and its directors, staff and employees, shall not be liable for any loss, damage of any kind, including but not limited to direct, indirect, punitive, exemplary, consequential damages and lost profits arising in any way out of the use of information / data obtained through the said websites. The recipient alone is fully responsible / liable for any decision made based on this information / data. All recipients, before relying on the said information, should do their own research and seek appropriate professional advice.</em></p> */}
   
      </div>):(<div ref={tab5Ref} style={{padding:"15px 15px",marginTop:"30px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0"}}>Calculator</h2>
        <h4 style={{color:"#666"}}>{ipoDetails?.IssuerCompany} Shares to Apply By Amount Calculator</h4>
<p style={{fontSize:"15px"}}>Enter the amount you would like to invest in {ipoDetails?.IssuerCompany} and find how many shares you should apply. In an IPO, shares are applied in lots. This calculator helps you to find an exact number of shares to apply for the given amount.</p>
   
<table style={{ 
      width: "100%", 
      maxWidth:"600px",
      minWidth:"auto",
      // borderCollapse: "collapse", 
      // borderRadius:"20px",
      padding: "10px 10px",
      border:"1px solid #e6eefb",
      // borderRadius:"15px",
      backgroundColor:"#e6eefb",
      fontFamily: "Arial, sans-serif", 
      fontSize: "15px" ,
      alignContent:"center",
      // textAlign:"center"
      // justifyContent:"center",
    }}>
      <tbody>
      <div style={{display:"flex",flexDirection:"column",flexWrap:"nowrap",gap:"10px",justifyContent:"center",alignContent:"center"}}>
        <tr>
          <td style={{ 
            padding: "10px",
            // backgroundColor: "#f2f2f2",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // border:"1px solid whitesmoke",
      // borderRadius:"15px",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lot Size (Shares)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
      // borderRadius:"15px",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            0
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Shares Issue Price (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            // borderRadius:"15px",
            backgroundColor: "white",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {pricePerShareRange2 || pricePerShareRange1 || 0}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lot Price (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            // borderRadius:"15px",
            backgroundColor: "white",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {lastValue.toLocaleString('en-IN') || 0}
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={{ 
            padding: "15px",
            backgroundColor: "white",
            fontWeight: "bold",
            // border: "1px solid #dddddd",
            textAlign: "center",
            width: "100%",
            // gap:"15px"
          }}>
            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"15px",justifyContent:"center",alignContent:"center",width: "100%", 
      maxWidth:"700px"}}>
            <label style={{color:"#666",padding: "8px 0"}}>Amount to Invest (Rs):</label>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="0"
              style={{ padding: "8px 16px", borderRadius: "5px", border: "1px solid #ccc",maxWidth:"180px",width:"100%",alignContent:"center",fontSize:"15px",backgroundColor:"#f1efef" }}
            />
            <button style={{ 
              padding: "8px 16px",
              background: "linear-gradient(90deg, rgba(61,89,121,1) 0%, rgba(107,128,179,1) 49%, rgba(149,170,205,1) 100%)",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}
            onClick={handleSubmit} >Find No. of Shares to Apply</button></div>
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Lots to Apply
          </td>
          <td style={{ 
            padding: "10px",
            alignContent:"center",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {LotApply || 0}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Shares to Apply
          </td>
          <td style={{ 
            padding: "10px",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            border: "1px solid #dddddd",
            alignContent:"center",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {ShareApply || 0}
          </td>
        </tr>
        <tr>
          <td style={{ 
            padding: "10px",
            fontWeight: "bold",
            color:"#666",
            textAlign:"left",
            // fontWeight: "bold",
            // border: "1px solid #dddddd",
            width: "40%"
          }}>
            Total Investment (Rs)
          </td>
          <td style={{ 
            padding: "10px",
            // border:"1px solid whitesmoke",
            backgroundColor: "white",
            // borderRadius:"15px",
            alignContent:"center",
            border: "1px solid #dddddd",
            width: "60%",
            height:"auto",
            wordWrap:"break-word"
          }}>
            {TotalAmount || 0}
          </td>
        </tr>
        </div>
      </tbody>
    </table>

     </div>  )}

      <div ref={tab4Ref} style={{padding:"15px 15px",marginTop:"30px",marginBottom:"30px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <h2 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0"}}>Allotment Status</h2>
        {/* <hr/> */}

        {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0]?.data[4] ? (
        <p style={{fontSize:"15px"}}>{ipoDetails?.IssuerCompany} allotment is expected on <strong>{ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0]?.data[4]}</strong>.</p>
        ): null}

        {/* {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0].data[4] && (
  <>
    {(() => {
      const currentDate = new Date(); // Assuming currentDate is the current date
      const ipoTimeline = new Date(ipoDetails.ipo_timeline[0].data[4]); // Assuming ipoTimeline is the IPO date

      if (currentDate.toDateString() >= ipoTimeline.toDateString()) {
        // Show this line if current date is equal to ipo timeline date
        return <p style={{fontWeight:"bold"}}>The basis of allotment for {ipoDetails?.IssuerCompany} is available now.</p>;
      } else {
        // Show this line if current date is not equal to ipo timeline date
        return (
          <p style={{color:"red"}}>
            {ipoDetails?.IssuerCompany} allotment status is <strong>not available</strong> at this time. {ipoDetails?.IssuerCompany} - The allotment status will be available once the basis of allotment is finalized. Please revisit us to check the latest updates for {ipoDetails?.IssuerCompany} allotment status.
          </p>
        );
      }
    })()}
  </>
)} */}


{ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] ? (
  <>
    <p style={{fontWeight: "bold",fontSize:"15px"}}>
      The basis of allotment for {ipoDetails?.IssuerCompany} is available now.
    </p>
  </>
) : (
  <p style={{color: "red",fontSize:"15px"}}>
    {ipoDetails?.IssuerCompany} allotment status is <strong>not available</strong> at this time. {ipoDetails?.IssuerCompany} - The allotment status will be available once the basis of allotment is finalized. Please revisit us to check the latest updates for {ipoDetails?.IssuerCompany} allotment status.
  </p>
)}




        <p style={{fontSize:"15px",fontWeight:"bold"}}>To check {ipoDetails?.IssuerCompany} allotment status, follow the steps below:</p>

        <p style={{fontSize:"15px"}}>1. Click on the below allotment status check button.<br/>
        2. Select Company Name.<br/>
        3. Enter your PAN Number, Application Number or DP Client ID (Anyone).<br/>
        4. Click on Search.</p>

        <p style={{fontSize:"15px"}}>On securing the allotment, you will receive the credit of equivalent shares in your Demat account.</p>

        {/* {ipoDetails.ipo_timeline && ipoDetails.ipo_timeline[0].data[4] && (function() {
          const currentDate1 = new Date(); // Assuming currentDate is the current date
          const ipoTimeline1 = new Date(ipoDetails.ipo_timeline[0].data[4]); // Assuming ipoTimeline is the IPO date

          if (currentDate1.toDateString() >= ipoTimeline1.toDateString()) {
            // Show this line if current date is equal to ipo timeline date
            return  <div style={{marginBottom:"10px",padding:"10px 10px",width:"auto",color: "#ffffff",animation: "colorChange 2s infinite alternate",backgroundColor:"green",borderRadius:"10px",textAlign:"center"}}>
                    <Link to="https://maashitla.com/allotment-status/public-issues" target="_blank" style={{textDecoration: "none",color: "#ffffff"}} title={`${ipoDetails?.IssuerCompany} Allotment Status`}>
                        <b>{ipoDetails?.IssuerCompany} Allotment Status</b>
                    </Link>
                </div>;
          } else {
            // Show this line if current date is not equal to ipo timeline date
            return (
              <div style={{marginBottom:"10px",padding:"10px 10px",width:"auto",color: "#666",backgroundColor:"black",borderRadius:"10px",textAlign:"center",backgroundColor:"whitesmoke",border:"1px solid #666"}}>

                  <b>{ipoDetails?.IssuerCompany} Allotment Status</b>
              
            </div>
            );
          }
        })()} */}


{ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status.length > 0 && ipoDetails.ipo_allotment_status[0].data[2] ? (
  <>
    <div style={{marginBottom:"10px",padding:"10px 10px",width:"auto",color: "#ffffff",animation: "colorChange 2s infinite alternate",backgroundColor:"green",borderRadius:"10px",textAlign:"center"}}>
                    <div style={{textDecoration: "none",color: "#ffffff"}} title={`${ipoDetails?.IssuerCompany} Allotment Status`}>
                        <b onClick={() => handleClick(ipoDetails.ipo_allotment_status && ipoDetails.ipo_allotment_status[0].data[2])}>{ipoDetails?.IssuerCompany} Allotment Status</b>
                    </div>
                </div>
  </>
) : (
  <div style={{marginBottom:"10px",padding:"10px 10px",width:"auto",color: "#666",backgroundColor:"whitesmoke",borderRadius:"10px",textAlign:"center",border:"1px solid #666"}}>

  <b>{ipoDetails?.IssuerCompany} Allotment Status</b>

</div>
)}



        {/* <p>If you have any concerns or queries about the allotment status, contact Maashitla Securities Private Limited either by phone or email. Investors can call on +91-11-45121795-96 or send an email with all relevant details to ipo@maashitla.com.</p> */}
        {/* <div style={{marginBottom:"10px",padding:"10px 10px",width:"auto",color: "#ffffff",animation: "colorChange 2s infinite alternate",backgroundColor:"green",borderRadius:"10px",textAlign:"center"}}>
            <Link to="https://maashitla.com/allotment-status/public-issues" target="_blank" style={{textDecoration: "none",color: "#ffffff"}} title={`${ipoDetails?.IssuerCompany} Allotment Status`}>
                <b>{ipoDetails?.IssuerCompany} Allotment Status</b>
            </Link>
        </div> */}
      </div>

      <div className="faq-container" style={{marginBottom:"30px",width: "82%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>
      <Typography variant="h6" gutterBottom style={{ marginBottom: "15px", fontSize: "18px" }}>
        Frequently Asked Questions
        {/* FAQs */}
      </Typography>
      {faqData?.map((faq, index) => {
        const isExpanded = expanded === index;

        return (
          <Accordion
            key={index}
            expanded={isExpanded}
            onChange={() => setExpanded(isExpanded ? null : index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography className="faq-question" style={{ fontSize: "14px" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className={`faq-answer ${isExpanded ? 'expanded' : 'collapsed'}`}
              >
                {/* <Typography style={{ fontSize: "14px" }}>
                  {faq.answer}
                </Typography> */}
                 <Typography style={{ fontSize: '14px',margin:"-15px 0 -15px 0",padding:"0" }} dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>

      </div>





      {/* <div
        style={{
          display: "flex",
          alignItems: "left",
          padding: "25px 85px",
          gap: "15px",
          background: "green",
          overflowX: "auto", // Enable horizontal scrolling for small screens
          whiteSpace: "nowrap", // Prevent wrapping of links
          maxWidth: "100%", // Ensure the div doesn't exceed the viewport width
        }}
      >
        <Link to={`/ipo/${ipoDetails?.IssuerCompany}`} style={{ marginRight: "35px" }}>
          <p
            style={{
              color: ipoType === "All IPO" ? "red" : "inherit",
              margin: "0",
            }}
          >
            IPO Details
          </p>
        </Link>
        <Link to={`/ipo/news`} style={{ marginRight: "35px" }}>
          <p
            style={{
              color: ipoType === "Main IPO" ? "red" : "inherit",
              margin: "0",
            }}
          >
            News
          </p>
        </Link>
        <Link to={`/ipo/subscription`} style={{ marginRight: "35px" }}>
          <p
            style={{
              color: ipoType === "SME IPO" ? "red" : "inherit",
              margin: "0",
            }}
          >
            Subscription
          </p>
        </Link>
        <Link to={`/ipo/allotment`}>
          <p
            style={{
              color: ipoType === "SME IPO" ? "red" : "inherit",
              margin: "0",
            }}
          >
            Allotment
          </p>
        </Link>
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div className="max-w-screen-xl px-4 sm:px-6 lg:px-8 mx-auto w-full sm:flex-row pt-6 pb-5 shadow-lg mb-5 overflow-auto">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="text-2xl pb-6 bold">{ipoDetails?.IssuerCompany}</h1>
            <div
              className="px-2 py-2 text-gray-500 text-sm"
              style={{ textAlign: "right" }}
            >
              Investment/lot
              <div className="flex text-[20px] text-black-600 gap-2">
                <p>₹ 14820 / 26 shares</p>
              </div>
            </div>
          </div>
          <h1 className="text-2xl pb-4 bold">IPO Details</h1>
          <hr className="mb-3" />
          <div>
            <table className="text-left w-full">
              <tbody>
                {data1.map((row, index) => (
                  <tr key={index} className="">
                    <td className="px-2 py-2 text-md">
                      Issue Date
                      <div className="flex text-[12px] text-gray-500 gap-2">
                        <p>{row[0]}</p>
                      </div>
                    </td>
                    <td className="px-2 py-2 text-md">
                      Investment
                      <div className="flex text-[12px] text-gray-500 gap-2">
                        <p>{row[1]}</p>
                      </div>
                    </td>
                    <td className="px-2 py-2 text-md">
                      Price Range
                      <div className="flex text-[12px] text-gray-500 gap-2">
                        <p>{row[2]}</p>
                      </div>
                    </td>
                    <td className="px-2 py-2 text-md">
                      Lot Size
                      <div className="flex text-[12px] text-gray-500 gap-2">
                        <p>{row[3]}</p>
                      </div>
                    </td>
                    <td className="px-2 py-2 text-md">
                      IPO Size
                      <div className="flex text-[12px] text-gray-500 gap-2">
                        <p>{row[4]}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}

        {/* <div className="max-w-screen-xl px-4 sm:px-6 lg:px-8 mx-auto w-full sm:flex-row pt-6 pb-5 shadow-lg mb-5 ">
          IPO Timeline
        </div> */}


        {/* <div style={{ padding: "25px 85px" }}>
          <TableContainer
            style={{ width: "90%", maxWidth: "1200px", marginBottom: "10px" }}
          >
            <Table sx={{ minWidth: 350 }} aria-label="customized table">
              <caption style={{ textAlign: "right" }}>
                {amountInCaption}
              </caption>
              <TableHead>
                <TableRow>
                  {columns.map((columnName, index) => (
                    <StyledTableCell key={index}>
                      {columnName.replace(/_/g, " ")}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => (
                  <StyledTableRow key={index}>
                    {row
                      .filter((i) => i != "")
                      .map((cell, cellIndex) => (
                        <StyledTableCell key={cellIndex}>
                          {cell}
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
     */}


    </div>
    <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
            
    </>
  );
}

export default IPODetails;